import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSandbox } from './app.sandbox';
import { ApplicationStateHandlers } from './app.state.handlers';
import { GraphQLModule } from './graphql.module';
import { AuthUtilService } from './services/authUtil.service';
import { GeoService } from './services/geo.service';
import { Config } from './config';
import { Logger } from './logger';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { DatePipe, KeyValuePipe } from '@angular/common';
import { MapService } from './services/map.service';
import { PushMessagingService } from './services/pushMessaging.service';

import { initializeApp } from 'firebase/app';
import { BASE_PATH } from '../generated-client';

initializeApp(environment.firebaseConfig);

class MyErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    // do something with the error
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireMessagingModule,
    NgxsModule.forRoot([ApplicationStateHandlers], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['app']
    }),
    NgxsLoggerPluginModule.forRoot({ collapsed: true }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    IonicModule.forRoot({
      swipeBackEnabled: false
    }),
    GraphQLModule,
    NoopAnimationsModule,
    FontAwesomeModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: MyErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppSandbox,
    AuthUtilService,
    // { provide: BASE_PATH, useValue: '/api' },
    {
      provide: APP_INITIALIZER,
      useFactory: (http: HttpClient) => {
        const logger = new Logger('AppModule');
        return async () => {
          let config;
          if (!environment.production) {
            // dev mode, so check for a dev config file override
            try {
              config = await http.get('/config.dev.json').toPromise();
            } catch (err) {
              logger.debug('dev config file not found, using prod', err);
            }
          }
          if (!config) {
            config = await http.get('/config.json').toPromise();
          }
          logger.debug('loaded config', config);
          const substitute = (value: string): string => {
            let newValue = value;
            newValue = newValue.replace(/{{protocol}}/g, window.location.protocol);
            newValue = newValue.replace(/{{hostname}}/g, window.location.hostname);
            newValue = newValue.replace(/{{port}}/g, window.location.port);
            newValue = newValue.replace(/{{host}}/g, window.location.host);
            newValue = newValue.replace(/{{origin}}/g, window.location.origin);
            newValue = newValue.replace(/{{pathname}}/g, window.location.pathname);
            return newValue;
          };
          if (!!config && !!config.contentApiBaseRestUrl) {
            Config.contentApiBaseRestUrl = substitute(config.contentApiBaseRestUrl);
          }
          if (!!config && !!config.contentApiBaseGraphqlUrl) {
            Config.contentApiBaseGraphqlUrl = substitute(config.contentApiBaseGraphqlUrl);
          }
          if (!!config && !!config.integrationApiBaseRestUrl) {
            Config.integrationApiBaseRestUrl = substitute(config.integrationApiBaseRestUrl);
          }
          if (!!config && !!config.mapApiKey) {
            Config.mapApiKey = config.mapApiKey;
          }
          if (!!config && !!config.cloudMessagingKey) {
            Config.cloudMessagingKey = config.cloudMessagingKey;
          }
          if (!!config && config.quotingEnabled != null) {
            Config.quotingEnabled = config.quotingEnabled;
          }
          if (!!config && config.bookingEnabled != null) {
            Config.bookingEnabled = config.bookingEnabled;
          }
          logger.debug('using base URLs', Config.contentApiBaseRestUrl, Config.contentApiBaseGraphqlUrl);
        };
      },
      multi: true,
      deps: [HttpClient]
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, authService: AuthUtilService) {
        const auth = setContext(async (operation, context) => {
          const token = await authService.getCurrentAuthToken();
          // console.log('apollo using token', !!token);
          if (token === null) {
            // console.log('no auth header used');
            return {};
          }
          // console.log('auth header used');
          return {
            headers: {
              Authorization: token
            }
          };
        });
        const cache = new InMemoryCache();
        // console.log('returning link');
        return {
          link: auth.concat(httpLink.create({ uri: Config.contentApiBaseGraphqlUrl })),
          cache
        };
      },
      deps: [HttpLink, AuthUtilService]
    },
    Geolocation,
    GeoService,
    DatePipe,
    KeyValuePipe,
    MapService,
    PushMessagingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
    // if (environment.production === true) {
    Logger.setLogLevel('warn');
    // }
  }
}
