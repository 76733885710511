import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  LogoutAction,
  UpdateAuthTokensAction,
  UpdateBookedLoadsAction,
  UpdateMapStateAction,
  UpdateOnboardingAction,
  UpdatePreferencesAction,
  UpdateProfileAction
} from './app.actions';
import { ApplicationStateHandlers, MapState } from './app.state.handlers';
import { BookedLoad, Preferences } from '../generated/graphql';
import { Onboarding } from './common.models';
import { cloneDeep } from '@apollo/client/utilities';

@Injectable()
export class AppSandbox {
  @Select(ApplicationStateHandlers.profile)
  public profile$: Observable<any>;

  @Select(ApplicationStateHandlers.onboarding)
  public onboarding$: Observable<Onboarding>;

  @Select(ApplicationStateHandlers.preferences)
  public preferences$: Observable<Preferences>;

  @Select(ApplicationStateHandlers.authToken)
  public authToken$: Observable<string>;

  @Select(ApplicationStateHandlers.refreshToken)
  public refreshToken$: Observable<string>;

  @Select(ApplicationStateHandlers.mapState)
  public mapState$: Observable<MapState>;

  @Select(ApplicationStateHandlers.lastEmail)
  public lastEmail$: Observable<String>;

  @Select(ApplicationStateHandlers.bookedLoads)
  public bookedLoads$: Observable<BookedLoad[]>;

  constructor(private store: Store) {}

  getCurrentPreferences() {
    let localPrefs: Preferences = undefined;
    const sub = this.preferences$.subscribe(prefs => (localPrefs = prefs));
    sub.unsubscribe();
    return localPrefs;
  }

  getCurrentProfile() {
    let localProfile: any = undefined;
    const sub = this.profile$.subscribe(profile => (localProfile = profile));
    sub.unsubscribe();
    return localProfile;
  }

  getLastEmail() {
    let lastEmail: any = undefined;
    const sub = this.lastEmail$.subscribe(currentLastEmail => (lastEmail = currentLastEmail));
    sub.unsubscribe();
    return lastEmail;
  }

  getBookedLoads(): BookedLoad[] {
    return this.store.selectSnapshot(state => state.app.bookedLoads);
  }

  updateProfile(profile: any, merge = false): void {
    if (merge) {
      let currentProfile = cloneDeep(this.getCurrentProfile());
      if (!currentProfile) {
        currentProfile = {};
      }
      currentProfile = Object.assign(currentProfile, profile);
      this.store.dispatch(new UpdateProfileAction(profile));
    } else {
      this.store.dispatch(new UpdateProfileAction(profile));
    }
  }

  updateOnboarding(onboarding: Onboarding): void {
    this.store.dispatch(new UpdateOnboardingAction(onboarding));
  }

  updateAuthTokens(authToken: string, refreshToken: string): void {
    this.store.dispatch(new UpdateAuthTokensAction(authToken, refreshToken));
  }

  updateBookedLoads(bookedLoads: BookedLoad[]): void {
    this.store.dispatch(new UpdateBookedLoadsAction(bookedLoads));
  }

  updatePreferences(preferences: Preferences, merge = false): void {
    if (merge) {
      let currentPrefs = cloneDeep(this.getCurrentPreferences());
      if (!currentPrefs) {
        currentPrefs = {};
      }
      currentPrefs = Object.assign(currentPrefs, preferences);
      this.store.dispatch(new UpdatePreferencesAction(currentPrefs));
    } else {
      this.store.dispatch(new UpdatePreferencesAction(preferences));
    }
  }

  updateMapState(mapState: MapState): void {
    this.store.dispatch(new UpdateMapStateAction(mapState));
  }

  haveToken(): boolean {
    const app = JSON.parse(localStorage.getItem('app'));
    return !!app.authToken;
  }

  reset(state: any) {
    this.store.reset(state);
  }

  logout(soft = true): void {
    this.store.dispatch(new LogoutAction(soft));
  }
}
