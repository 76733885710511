import { Injectable } from '@angular/core';
import { Geolocation as NativeGeolocation } from '@ionic-native/geolocation/ngx';
import { Geolocation } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationData } from '../common.models';
import { InjectLogger } from '../decorators';
import { Logger } from '../logger';
import { Global } from './global.service';
import to from 'await-to-js';

// declare const cordova: any;

@Injectable()
export class GeoService {
  @InjectLogger() logger: Logger;
  GEO_UPDATE_INTERVAL = 15000;
  GEO_MAX_AGE = 15000;
  GEO_TIMEOUT = this.GEO_MAX_AGE;

  geoData = new BehaviorSubject<LocationData>(null);

  interval: any;

  constructor(private platform: Platform, private geolocation: NativeGeolocation) {
    this.platform.ready().then(() => {
      // cordova.plugins.diagnostic.isLocationEnabled((res) => {
      //   if (res === true) {
      //   }
      // });

      this.init();
    });
  }

  overrideCurrentLocation(location) {
    this.logger.debug('overrideCurrentLocation(): overriding current location:', location);
    Global.OVERRIDE_CURRENT_LOCATION = {
      latitude: location.latitude,
      longitude: location.longitude
    };

    // now update the location
    this.geoData.next(Global.OVERRIDE_CURRENT_LOCATION);
  }

  watchPosition(): Observable<LocationData> {
    return this.geoData.asObservable();
  }

  async getPosition(): Promise<LocationData> {
    this.logger.debug('getPosition()');
    return new Promise((res, rej) => {
      const sub = this.geoData.subscribe(l => {
        // this.logger.debug('getPosition(): got location in subscription', l);
        res(l);
        sub.unsubscribe();
      });
    });
  }

  async init() {
    this.logger.debug('have platforms', this.platform.platforms());
    if (this.platform.is('capacitor')) {
      try {
        await Geolocation.requestPermissions();
      } catch (e) {
        this.logger.error('init(): error requesting geolocation permissions');
      }
    }

    try {
      await this.updateCurrentLocation();
    } catch (e) {
      // ignore
    }

    try {
      // start up the background geo
      await this.startBackgroundGeolocation();
    } catch (e) {
      // ignore
    }

    // fetch initial position at all costs, including using the regular geo service to get rough location
    try {
      if (!!Global.OVERRIDE_CURRENT_LOCATION) {
        this.logger.debug('init(): broadcasting override location');
        this.broadcastLocation(null);
      } else {
        this.logger.debug('init(): broadcasting initial location');
        const location = await this.getLocation();
        this.broadcastLocation(location);
      }
    } catch (e) {
      // ignore
    }
  }

  broadcastLocation(location: LocationData) {
    if (!!Global.OVERRIDE_CURRENT_LOCATION) {
      this.logger.debug('broadcastLocation(): overriding current location: ', Global.OVERRIDE_CURRENT_LOCATION);
      this.geoData.next(Global.OVERRIDE_CURRENT_LOCATION);
    } else {
      // this.logger.debug('broadcastLocation(): sending updated location');
      this.geoData.next(location);
    }
  }

  /**
   * Setup background geo service
   */
  async startBackgroundGeolocation() {
    if (!this.platform.is('capacitor')) {
      this.logger.debug('startBackgroundGeolocation(): tracking current position');
      this.trackCurrentPosition();
      return;
    }
  }

  /**
   * create an interval timer that polls GPS (primarily used on the web when native service is not available)
   */
  trackCurrentPosition() {
    if (!this.interval) {
      this.updateCurrentLocation();
      this.interval = setInterval(async () => {
        await this.updateCurrentLocation();
      }, this.GEO_UPDATE_INTERVAL);
    }
  }

  /**
   * stop the interval timer
   */
  untrackCurrentPosition() {
    if (!!this.interval) {
      clearInterval(this.interval);
    }
  }

  async updateCurrentLocation() {
    // this.logger.debug('updateCurrentLocation(): getting current location');
    this.broadcastLocation(await this.getLocation());
  }

  /**
   * Get location from background geo, or barring that from the regular geo service
   */
  async getLocation(): Promise<LocationData> {
    // this.logger.debug('getLocation()');

    return new Promise<LocationData>(async (resolve, reject) => {
      // fall back to regular geo if necessary
      let [err, location] = await to(this.getLocationFromCapacitor());

      if (!!err) {
        this.logger.warn('getLocation(): failed to get capacitor geo location', err.message);
        [err, location] = await to(this.getLocationFromRegularGeo());
      }

      if (!!err) {
        this.logger.error('getLocation(): failed to get backup geo location', err.message);
        return reject(err);
      }

      resolve(location);
    });
  }

  async getLocationFromCapacitor(): Promise<LocationData> {
    // this.logger.debug('getLocationFromCapacitor()');
    const [err, location] = await to(
      Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: this.GEO_TIMEOUT
      })
    );

    if (!!err) {
      this.logger.error('getLocationFromCapacitor(): error', err.message);
      return;
    }

    // this.logger.debug('getLocationFromCapacitor(): got current position', location);

    return {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      accuracy: location.coords.accuracy,
      altitude: location.coords.altitude,
      bearing: location.coords.heading,
      speed: location.coords.speed
    };
  }

  async getLocationFromRegularGeo(): Promise<LocationData> {
    // this.logger.debug('getLocationFromRegularGeo()');
    const [err, location] = await to(
      this.geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: this.GEO_TIMEOUT
      })
    );

    if (!!err) {
      this.logger.error('getLocationFromRegularGeo(): error', err.message);
      return;
    }

    this.logger.debug('getLocationFromRegularGeo(): got current position', location);

    return {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      accuracy: location.coords.accuracy,
      altitude: location.coords.altitude,
      bearing: location.coords.heading,
      speed: location.coords.speed
    };
  }
}
