import { take } from 'rxjs/operators';
import to from 'await-to-js';
import {
  AuthenticateDocument,
  AuthenticationResult,
  JwtDecoded,
  PasswordlessAuthenticateDocument,
  PasswordlessOtpDocument,
  ValidateTokenDocument
} from '../../generated/graphql';
import { cloneDeep } from '@apollo/client/utilities';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { InjectLogger } from '../decorators';
import { Logger } from '../logger';
import { AppSandbox } from '../app.sandbox';
import { AuthUtilService } from './authUtil.service';
import { Router } from '@angular/router';
import { Coords, RouteService } from '../../generated-client';
import PlacesService = google.maps.places.PlacesService;
import PlaceResult = google.maps.places.PlaceResult;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;
import PlaceSearchPagination = google.maps.places.PlaceSearchPagination;
import { Config } from '../config';
const cities = require('../../assets/cities.json');

// export class Coords {
//   lat: number;
//   lng: number;
// }

export class City {
  STATE_CODE: string;
  STATE_NAME: string;
  CITY: string;
  LATITUDE: number;
  LONGITUDE: number;
}

export class ZipMappingEntry {
  'zip_code': number;
  'latitude': number;
  'longitude': number;
  'city': string;
  'state': string;
  'county': string;
}

@Injectable({
  providedIn: 'root'
})
export class MapService {
  @InjectLogger() logger: Logger;
  placesService = new PlacesService(document.createElement('div'));

  top = 49.3457868; // north lat
  left = -125.7844079; // west long
  right = -66.9513812; // east long
  bottom = 24.7433195; // south lat

  constructor(
    private router: Router,
    private apollo: Apollo,
    private appSandbox: AppSandbox,
    private routeService: RouteService
  ) {}

  async getRoute(coords: Coords[]): Promise<Coords[]> {
    this.logger.debug('getRoute()', coords);

    const token = await this.appSandbox.authToken$.pipe(take(1)).toPromise();

    if (!token) {
      // no token, logout
      this.appSandbox.logout();
      return;
    }

    this.routeService.configuration.basePath = Config.integrationApiBaseRestUrl;

    const [err, res] = await to(
      this.routeService
        .postRoute(token, coords, 'body', false, {
          httpHeaderAccept: 'application/json' as any
        })
        .toPromise()
    );

    if (!!err) {
      // this.logger.error('getRoute(): got error', err.message, err.stack);
      throw err;
    }

    this.logger.debug('getRoute(): got route', res);
    return res as any;
  }

  async getPlaces(searchText: string, max = 20): Promise<City[]> {
    this.logger.debug('getPlaces()', searchText);

    // const token = await this.appSandbox.authToken$.pipe(take(1)).toPromise();
    //
    // if (!token) {
    //   // no token, logout
    //   this.appSandbox.logout();
    //   return;
    // }

    if (!searchText) {
      return [];
    }

    let count = 0;

    let matchList: string[] = [];
    let matches: City[] = cities.filter(row => {
      // match with exact word boundary first
      if (count < max) {
        let cityRegex = new RegExp(searchText + '( |$)', 'i');
        let cityStartsRegex = new RegExp('^' + searchText + '( |$)', 'i');
        let cityStateRegex = new RegExp(searchText, 'i');

        let fullName = row.CITY + ', ' + row.STATE_CODE;
        if (matchList.includes(fullName)) {
          return false;
        }

        if (fullName.match(cityStateRegex)) {
          this.logger.debug('getPlaces(): matched with state', row.CITY, row.STATE_CODE);
          count++;
          matchList.push(fullName);
          return true;
        } else if (row.CITY.match(cityStartsRegex)) {
          this.logger.debug('getPlaces(): matched with starts', row.CITY, row.STATE_CODE);
          count++;
          matchList.push(fullName);
          return true;
        } else if (row.CITY.match(cityRegex)) {
          this.logger.debug('getPlaces(): matched with boundary', row.CITY, row.STATE_CODE);
          count++;
          matchList.push(fullName);
          return true;
        }
      }
      return false;
    });

    if (count <= max) {
      const moreMatches = cities.filter(city => {
        let regex = new RegExp(searchText, 'i');
        if (count < max && city.CITY.match(regex)) {
          let fullName = city.CITY + ', ' + city.STATE_CODE;
          if (matchList.includes(fullName)) {
            return false;
          }

          count++;
          matchList.push(fullName);
          this.logger.debug('getPlaces(): matched fuzzy', city.CITY);
        }
        return false;
      });

      matches = matches.concat(moreMatches);
      this.logger.debug('getPlaces(): matches', matches);
      matches.forEach(m => {
        m.LATITUDE = parseFloat(m.LATITUDE as any);
        m.LONGITUDE = parseFloat(m.LONGITUDE as any);
      });
    }

    this.logger.debug('getPlaces(): matched count', count, matches.length);

    return matches;

    // const states = ['AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY',
    //   'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH',
    //   'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']
    //
    // return new Promise<any[]>((resolve, reject) => {
    //   try {
    //     this.placesService.textSearch({
    //       query: searchText,
    //       type: 'locality',
    //       bounds: {
    //         north: this.top,
    //         east: this.right,
    //         south: this.bottom,
    //         west: this.left
    //       }
    //     }, (results: PlaceResult[], status: PlacesServiceStatus, pagination: PlaceSearchPagination) => {
    //       this.logger.debug('getPlaces(): get places', results);
    //       results = results.filter(r => {
    //         let found = false;
    //         for (const state of states) {
    //           if (!!r.formatted_address.match(`, ${state}`)) {
    //             found = true;
    //           }
    //         }
    //         return found;
    //       });
    //       resolve(results);
    //     });
    //   } catch (e) {
    //     this.logger.error('getPlaces(): error looking up places', e);
    //     reject(e);
    //   }
    // });
  }
}
