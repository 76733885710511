import { BookedLoad, Preferences } from '../generated/graphql';
import { MapState } from './app.state.handlers';
import { Onboarding } from './common.models';

export class UpdateProfileAction {
  static readonly type = '[Loadsmith] UpdateProfileAction';
  constructor(public profile: any) {}
}

export class UpdateOnboardingAction {
  static readonly type = '[Loadsmith] UpdateOnboardingAction';
  constructor(public onboarding: Onboarding) {}
}

export class UpdatePreferencesAction {
  static readonly type = '[Loadsmith] UpdatePreferencesAction';
  constructor(public preferences: Preferences) {}
}

export class UpdateAuthTokensAction {
  static readonly type = '[Loadsmith] UpdateAuthTokens';
  constructor(public authToken: string, public refreshToken: string) {}
}

export class UpdateBookedLoadsAction {
  static readonly type = '[Loadsmith] UpdateBookedLoadsAction';
  constructor(public bookedLoads: BookedLoad[]) {}
}

export class LogoutAction {
  static readonly type = '[Loadsmith] LogoutAction';
  constructor(public soft = true) {}
}

export class UpdateMapStateAction {
  static readonly type = '[Loadsmith] UpdateMapStateAction';
  constructor(public mapState: MapState) {}
}
