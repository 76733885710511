import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppSandbox } from '../app.sandbox';
import { InjectLogger } from '../decorators';
import { Logger } from '../logger';

@Injectable({
  providedIn: 'root'
})
export class AuthUtilService {
  @InjectLogger() logger: Logger;

  loggedIn: boolean = null;
  // haveToken = false;

  isAuthenticated$ = new BehaviorSubject<boolean>(false);

  // Create subject and public observable of user profile data
  // userProfileSubject$ = new BehaviorSubject<any>(null);

  constructor(private router: Router, private appSandbox: AppSandbox) {
    this.localAuthSetup().then(() => {
      this.appSandbox.profile$.subscribe(async profile => {
        this.logger.debug('profile updated', profile);
        // localStorage.setItem('profile', JSON.stringify(profile));
        // this.userProfileSubject$.next(profile);
      });

      this.appSandbox.authToken$.subscribe(async token => {
        // this.logger.debug('authToken updated', token);

        if (!token) {
          // we just logged out, now redirect
          this.isAuthenticated$.next(false);
          // if (this.haveToken) {
          //   this.logger.debug('logged out, redirecting');
          //   this.router.navigateByUrl('/login');
          // } else {
          this.logger.debug('already logged out, not redirecting');
          // }
        } else {
          this.isAuthenticated$.next(true);
        }
      });
    });
  }

  async getCurrentAuthToken(): Promise<string> {
    return this.appSandbox.authToken$.pipe(take(1)).toPromise();
  }

  async localAuthSetup() {
    this.logger.debug('localAuthSetup()');

    // This should only be called on app initialization
    // Set up local authentication streams
    // const checkAuth$ = this.isAuthenticated$.pipe(
    //   concatMap((loggedIn: boolean) => {
    //     if (loggedIn) {
    //       this.logger.debug('localAuthSetup(): logged in');
    //       // If authenticated, get user and set in app
    //       // NOTE: you could pass options here if needed
    //       return this.getUser$();
    //     }
    //     // If not authenticated, return stream that emits 'false'
    //     this.logger.debug('localAuthSetup(): NOT logged in');
    //     return of(loggedIn);
    //   }),
    //   catchError(err => {
    //     this.logger.error('localAuthSetup(): error', err);
    //     return throwError(err);
    //   })
    // );
    //
    // checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
    //   // If authenticated, response will be user object
    //   // If not authenticated, response will be 'false'
    //   this.loggedIn = !!response;
    //   this.logger.debug('localAuthSetup()', this.loggedIn);
    // }, err => {
    //   this.logger.error('localAuthSetup(): error', err);
    // });

    await this.updateAuthToken();
  }

  async updateAuthToken() {
    this.logger.debug('updateAuthToken()');
    await this.checkTokens();
  }

  async checkTokens() {
    // check token
    // const raw = localStorage.getItem('app');
    // this.logger.debug('updateAuthToken(): have raw', raw);

    // this.appSandbox.authToken$.subscribe((token) => {
    //   this.logger.debug('updateAuthToken(): updated token', token);
    // });

    // this.appSandbox.profile$.subscribe(async profile => {
    //   this.logger.debug('checkTokens(): profile updated', profile);
    //   localStorage.setItem('profile', JSON.stringify(profile));
    //   this.userProfileSubject$.next(profile);
    // });

    const token = await this.appSandbox.authToken$.pipe(take(1)).toPromise();

    if (!!token) {
      this.logger.debug('updateAuthToken(): have token');
      const decoded: any = jwt_decode(token);
      const expired = Date.now() >= decoded.exp * 1000;

      // we have profile, so check token
      this.logger.debug('updateAuthToken(): token expired?', expired);
      this.isAuthenticated$.next(expired === false);
    } else {
      this.logger.debug('updateAuthToken(): no token');
      this.isAuthenticated$.next(false);
    }
  }

  logout(soft = true) {
    this.logger.debug('logout()');
    this.appSandbox.logout(soft);
    this.isAuthenticated$.next(false);
    // reload here
    window.location.reload();
  }
}
