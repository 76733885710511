import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private darkTheme: BehaviorSubject<boolean>;
  isDarkMode: Observable<boolean>;

  constructor() {
    const darkModeFromStorage = localStorage.getItem('isDarkMode') !== 'false';
    this.darkTheme = new BehaviorSubject<boolean>(darkModeFromStorage);

    this.isDarkMode = this.darkTheme.asObservable();
  }

  toggleTheme(): void {
    const nextThemeStatus = !this.darkTheme.getValue();
    localStorage.setItem('isDarkMode', String(nextThemeStatus));
    this.darkTheme.next(nextThemeStatus);
  }
}
