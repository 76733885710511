import { Injectable } from '@angular/core';
import { InjectLogger } from '../decorators';
import {
  CreatePreferencesDocument,
  GetPreferencesDocument,
  Preferences,
  PreferencesInput,
  UpdatePreferencesDocument
} from '../../generated/graphql';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from '@apollo/client/utilities';
import to from 'await-to-js';
import { AppSandbox } from '../app.sandbox';
import { OnboardingStatusService } from '../../generated-client';
import { take } from 'rxjs/operators';
import { ErrorsUtil } from '../../errors.util';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  @InjectLogger() logger;

  constructor(
    private apollo: Apollo,
    private sandbox: AppSandbox,
    private onboardingService: OnboardingStatusService
  ) {}

  async getPreferences(): Promise<Preferences> {
    this.logger.debug('getPreferences()');

    const [err, res] = await to(
      this.apollo
        .query<any>({
          query: GetPreferencesDocument,
          variables: { id: 'owner', env: 'all' },
          fetchPolicy: 'no-cache'
        })
        .toPromise()
    );

    if (!!err) {
      this.logger.debug('getPreferences(): error');
      const firstError = ErrorsUtil.getFirstError(err);
      if (firstError && firstError.statusCode === 404) {
        this.logger.warn('getPreferences(): not found, returning null');
        return null;
      }

      this.logger.error('getPreferences(): got error, throwing');
      throw err;
    }

    this.logger.debug('getPreferences(): res', res);

    const prefs = cloneDeep(res.data.getPreferences);
    delete prefs.__typename;
    return prefs;
  }

  async savePreferences(preferences: Preferences | PreferencesInput): Promise<Preferences> {
    this.logger.debug('savePreferences()');

    if (!!preferences.id) {
      this.logger.debug('savePreferences(): update');

      // Fix numeric preferences types before sending data to Apollo
      preferences.maxWeight =
        preferences.maxWeightEnabled && preferences.maxWeight ? parseInt(`${preferences.maxWeight}`) : null;
      preferences.minDistance =
        preferences.minDistanceEnabled && preferences.minDistance ? parseFloat(`${preferences.minDistance}`) : null;
      preferences.maxDistance =
        preferences.maxDistanceEnabled && preferences.maxDistance ? parseFloat(`${preferences.maxDistance}`) : null;

      const [error, res] = await to(
        this.apollo
          .mutate<any>({
            mutation: UpdatePreferencesDocument,
            variables: { preferences }
          })
          .toPromise()
      );

      this.logger.debug('savePreferences(): result', res, error);
      if (error) {
        throw error;
      }

      const prefs = cloneDeep(res.data.updatePreferences);
      delete prefs.__typename;

      // update saved preferences
      this.sandbox.updatePreferences(prefs);

      return res.data.updatePreferences;
    } else {
      this.logger.debug('savePreferences(): create');

      const [error, res] = await to(
        this.apollo
          .mutate<any>({
            mutation: CreatePreferencesDocument,
            variables: { preferences, env: 'default' }
          })
          .toPromise()
      );

      this.logger.debug('savePreferences(): result', res, error);
      if (error) {
        throw error;
      }

      const prefs = cloneDeep(res.data.createPreferences);
      delete prefs.__typename;

      // update saved preferences
      this.sandbox.updatePreferences(prefs);

      return prefs;
    }
  }

  async getOnboardingStatus(dotNumber, email, phone) {
    this.logger.debug('getOnboardingStatus()');

    // const preferences = await this.sandbox.preferences$.pipe(take(1)).toPromise();
    //
    // const token = await this.sandbox.authToken$.pipe(take(1)).toPromise();
    //
    // if (!token) {
    //   // no token, logout
    //   this.sandbox.logout();
    //   return;
    // }

    this.onboardingService.configuration.basePath = Config.integrationApiBaseRestUrl;

    this.logger.debug('getOnboardingStatus(): using DOT', dotNumber, 'email', email);
    const [err, res] = await to(
      this.onboardingService
        .getOnboardingstatus(dotNumber, email, phone, 'body', false, {
          httpHeaderAccept: 'application/json' as any
        })
        .toPromise()
    );

    if (!!err) {
      this.logger.error('getOnboardingStatus(): got error', err.message, err.stack);
      throw err;
    }

    this.logger.debug('getOnboardingStatus(): got onboarding status', res);
    return res;
  }
}
