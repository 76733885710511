import { Injectable } from '@angular/core';
import * as loglevel from 'loglevel';
import { environment } from '../environments/environment';

declare let window: any;

@Injectable()
export class LoggerConfig {
  constructor() {
    loglevel.info('Logger(): info: instantiated');
  }

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // replace console functions
      // console.log = function() {
      //   // winston.log.apply(this, ['debug', ...this.processArgs('debug', ...arguments)]);
      //   winston.log.apply(this, ['debug', ...this.toArray(arguments)]);
      // };
      // // tslint:disable-next-line
      // console.info = function() {
      //   // winston.log.apply(this, ['info', ...this.processArgs('info', ...arguments)]);
      //   winston.log.apply(this, ['info', ...this.toArray(arguments)]);
      // };
      // console.warn = function() {
      //   // winston.log.apply(this, ['warn', ...this.processArgs('warn', ...arguments)]);
      //   winston.log.apply(this, ['warn', ...this.toArray(arguments)]);
      // };
      // console.error = function() {
      //   // winston.log.apply(this, ['error', ...this.processArgs('error', ...arguments)]);
      //   winston.log.apply(this, ['error', ...this.toArray(arguments)]);
      // };
      resolve();
    });
  }

  toArray(args) {
    const arr = [];
    for (let i = 0; i < args.length; i++) {
      arr.push(args[i]);
    }
    return arr;
  }

  processArgs(type, args) {
    // for error messages and critical error messages
    // we need should try and provide a backtace for debugging
    // also if the we've got a full error object, try to
    // simplfy the message to something useful
    let stack;
    if (type === 'error' || type === 'critical') {
      let err = args[0];
      stack = err ? err.stack : undefined;
      if (!stack) {
        const error_info = {};
        let sCallerName;
        {
          const re = /(\w+)@|at (\w+) \(/g;
          const aRegexResult = re.exec(new Error().stack);
          sCallerName = aRegexResult[1] || aRegexResult[2];
        }
        // Error.captureStackTrace(error_info, sCallerName);
        stack = error_info['stack'];
      }

      // extract a useful error message
      if (err instanceof Error) {
        err = err.message;
      } else if (typeof err === 'object' && err.message) {
        err = err.message;
      }
      args[0] = err;
    }

    const meta = { timestamp: new Date() };
    if (stack) {
      meta['stack'] = JSON.stringify(stack, null, '\t');
    }
    args.push(meta);

    return args;
  }
}

export class Logger {
  private name: string;
  private isFunction = false;

  constructor(private nameOrFunc: string | Function) {
    // new
    if (typeof nameOrFunc === 'string') {
      this.name = nameOrFunc;
    } else {
      this.name = !!nameOrFunc.name ? nameOrFunc.name : nameOrFunc.constructor.name;
      this.isFunction = true;
    }

    if (environment.production) {
      loglevel.setLevel(loglevel.levels.WARN);
    } else {
      loglevel.setLevel(loglevel.levels.TRACE);
    }

    // console.log('Logger(): set logger name to', this.name);
  }

  static setLogLevel(level: 'silent' | 'trace' | 'debug' | 'info' | 'warn' | 'error') {
    loglevel.setLevel(level);
  }

  forcedDebugLogger(level: string, format: string, ...params: any[]) {
    // this.winstonLogger.debug([(!!this.name ? this.name + (this.isFunction ? '()' : '') : 'Other()') + ': ' + format].concat(params));
  }

  haveDebugLevelTransport() {
    return loglevel.getLevel() <= loglevel.levels.DEBUG;
  }

  haveInfoLevelTransport() {
    return loglevel.getLevel() <= loglevel.levels.INFO;
  }

  debug(...params: any[]) {
    if (this.haveDebugLevelTransport()) {
      loglevel.debug(...this.processParams(params));
    }
  }

  info(...params: any[]) {
    if (this.haveInfoLevelTransport()) {
      loglevel.info(...this.processParams(params));
    }
  }

  warn(...params: any[]) {
    loglevel.warn(...this.processParams(params));
  }

  error(...params: any[]) {
    loglevel.error(...this.processParams(params));
  }

  processParams(params: any[]) {
    params = [this.name + (this.isFunction ? (!!params && params.length > 0 ? '():' : '()') : ': ')].concat(params);
    return params;
    // const newParams = params.map((p) => {
    //   if (!!p && typeof p === 'object' || typeof p === 'function') {
    //     if (p instanceof Date) {
    //       return p.toString();
    //     }
    //     return JSON.stringify(p);
    //   }
    //   return p;
    // });
    // const s: string = newParams.join(' ');
    // return s;
  }
}
