import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  LogoutAction,
  UpdateAuthTokensAction,
  UpdateBookedLoadsAction,
  UpdateMapStateAction,
  UpdateOnboardingAction,
  UpdatePreferencesAction,
  UpdateProfileAction
} from './app.actions';
import { InjectLogger } from './decorators';
import { Logger } from './logger';
import { BookedLoad, Preferences } from '../generated/graphql';
import { Onboarding } from './common.models';

export class MapState {
  zoom: number;
  center: {
    lat: number;
    lng: number;
  };
}

export class ApplicationStateModel {
  public profile: any;
  public onboarding: Onboarding;
  public authToken: string;
  public refreshToken: string;
  public preferences: Preferences;
  public mapState: MapState;
  public lastEmail: string;
  public bookedLoads: BookedLoad[];
}

@State<ApplicationStateModel>({
  name: 'app',
  defaults: {
    profile: null,
    onboarding: null,
    authToken: null,
    refreshToken: null,
    preferences: null,
    mapState: null,
    lastEmail: null,
    bookedLoads: []
  } as ApplicationStateModel
})
@Injectable()
export class ApplicationStateHandlers {
  @InjectLogger() logger: Logger;

  constructor() {
    // init
  }

  @Selector()
  static profile(state: ApplicationStateModel): any {
    return state.profile;
  }

  @Selector()
  static onboarding(state: ApplicationStateModel): any {
    return state.onboarding;
  }

  @Selector()
  static authToken(state: ApplicationStateModel): any {
    return state.authToken;
  }

  @Selector()
  static refreshToken(state: ApplicationStateModel): any {
    return state.refreshToken;
  }

  @Selector()
  static preferences(state: ApplicationStateModel): any {
    return state.preferences;
  }

  @Selector()
  static mapState(state: ApplicationStateModel): any {
    return state.mapState;
  }

  @Selector()
  static lastEmail(state: ApplicationStateModel): any {
    return state.lastEmail;
  }

  @Selector()
  static bookedLoads(state: ApplicationStateModel): any {
    return state.bookedLoads;
  }

  /**
   * Update Profile
   */
  @Action(UpdateProfileAction)
  updateProfile({ getState, patchState }: StateContext<ApplicationStateModel>, { profile }: UpdateProfileAction) {
    this.logger.debug('ApplicationState(): updateProfile()', profile);
    patchState({
      profile,
      lastEmail: profile.email
    });
  }

  /**
   * Update Onboarding
   */
  @Action(UpdateOnboardingAction)
  updateOnboarding(
    { getState, patchState }: StateContext<ApplicationStateModel>,
    { onboarding }: UpdateOnboardingAction
  ) {
    this.logger.debug('ApplicationState(): updateOnboarding()', onboarding);
    patchState({
      onboarding
    });
  }

  /**
   * Update Preferences
   */
  @Action(UpdatePreferencesAction)
  updatePreferences(
    { getState, patchState }: StateContext<ApplicationStateModel>,
    { preferences }: UpdatePreferencesAction
  ) {
    this.logger.debug('ApplicationState(): updatePreferences()', preferences);
    patchState({
      preferences
    });
  }

  /**
   * Update tokens
   */
  @Action(UpdateAuthTokensAction)
  updateAuthTokens(
    { getState, patchState }: StateContext<ApplicationStateModel>,
    { authToken, refreshToken }: UpdateAuthTokensAction
  ) {
    this.logger.debug('ApplicationState(): updateAuthTokens()', authToken, refreshToken);
    patchState({
      authToken,
      refreshToken
    });
  }

  /**
   * Update map state
   */
  @Action(UpdateMapStateAction)
  updateMapState({ getState, patchState }: StateContext<ApplicationStateModel>, { mapState }: UpdateMapStateAction) {
    this.logger.debug('ApplicationState(): updateMapState()', mapState);
    patchState({
      mapState
    });
  }

  /**
   * Update booked loads
   */
  @Action(UpdateBookedLoadsAction)
  updateBookedLoads(
    { getState, patchState }: StateContext<ApplicationStateModel>,
    { bookedLoads }: UpdateBookedLoadsAction
  ) {
    this.logger.debug('ApplicationState(): updateBookedLoads()', bookedLoads);
    patchState({
      bookedLoads
    });
  }

  /**
   * Logout
   */
  @Action(LogoutAction)
  logout({ getState, patchState }: StateContext<ApplicationStateModel>, { soft }: LogoutAction) {
    this.logger.debug('ApplicationState(): logout()');
    if (soft) {
      patchState({
        authToken: null,
        refreshToken: null
      });
    } else {
      patchState({
        authToken: null,
        refreshToken: null,
        profile: null,
        preferences: null,
        mapState: null
      });
    }
  }
}
