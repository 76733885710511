import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppSandbox } from '../app.sandbox';
import { InjectLogger } from '../decorators';
import { Logger } from '../logger';
import { AuthUtilService } from '../services/authUtil.service';
import { PreferencesService } from '../services/preferences.service';
import to from 'await-to-js';
import { Preferences } from '../../generated/graphql';
import { AlertController } from '@ionic/angular';
import { Global } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  @InjectLogger() logger: Logger;
  initialized = false;

  constructor(
    private auth: AuthUtilService,
    private sandbox: AppSandbox,
    private router: Router,
    private alertController: AlertController,
    private preferencesService: PreferencesService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe<boolean>(
      tap<boolean>(async loggedIn => {
        this.logger.debug('canActivate(): loggedIn', loggedIn);
        if (loggedIn === false) {
          // this.auth.login(state.url);
          this.logger.debug('canActivate(): checking isWeb');
          const isWeb = await Global.isWeb.pipe(take(1)).toPromise();
          this.logger.debug('canActivate(): isWeb', isWeb);

          if (isWeb) {
            this.logger.debug('canActivate(): not logged in, redirecting to web login page');
            await this.router.navigate(['/login'], {
              queryParams: { redirectTo: state.url }
            });
          } else {
            this.logger.debug('canActivate(): not logged in, redirecting to mobile login page');
            await this.router.navigate(['/mobile/login'], {
              queryParams: { redirectTo: state.url }
            });
          }
          return false;
        } else {
          this.logger.debug('canActivate(): logged in');
          await this.auth.updateAuthToken();
          this.logger.debug('canActivate(): updated auth token');

          // see if we have local prefs
          let profile = await this.sandbox.profile$.pipe(take(1)).toPromise();

          if (!profile || (!!profile && this.isNullOrEmpty(profile.phone)) || this.isNullOrEmpty(profile.name)) {
            this.logger.debug('canActivate(): check server prefs');

            // attempt to see if this user has preferences on the server with more info
            let [err, serverPrefs] = await to(this.preferencesService.getPreferences());
            if (!!serverPrefs && JSON.stringify(serverPrefs) !== JSON.stringify(profile)) {
              this.logger.debug('canActivate(): using server prefs instead of local');

              // use the server prefs instead of the local
              this.sandbox.updatePreferences(serverPrefs);
              profile = serverPrefs;

              // wait for the prefs to update above before continuing
              await this.sleep(500);
            }
          }

          this.logger.debug('canActivate(): using preferences', profile);

          // FIXME - mm - fix to enable when prefs are fixed
          // require that the user onboard, so if they have not, force redirect them here
          // if (!preferences ||
          //   this.isNullOrEmpty(preferences.USDOT) ||
          //   this.isNullOrEmpty(preferences.phone) ||
          //   this.isNullOrEmpty(preferences.firstName) ||
          //   this.isNullOrEmpty(preferences.lastName)) {
          //   this.logger.debug('canActivate(): user needs to be fully onboarded before moving forward', preferences);
          //   if (!!preferences) {
          //     this.logger.debug('canActivate(): preference fields: DOT', preferences.USDOT);
          //     this.logger.debug('canActivate(): preference fields: phone', preferences.phone);
          //     this.logger.debug('canActivate(): preference fields: firstName', preferences.firstName);
          //     this.logger.debug('canActivate(): preference fields: lastName', preferences.lastName);
          //   }
          //   await this.router.navigate(['/profile'], {
          //     queryParams: { onboarding: true }
          //   });
          //   return false;
          // } else {
          //   this.logger.debug('canActivate(): have all the preferences and onboarding');
          // }

          return true;
        }
      })
    );
  }

  sleep(millis): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, millis);
    });
  }

  isNullOrEmpty(dev) {
    if (!dev || dev.trim() === '') {
      return true;
    }
    return false;
  }
}
