
export class ErrorsUtil {
  static getFirstError(error: any) {
    console.log('error', !!error, 'graphqlErrors', !!error.graphQLErrors);
    if (!error || !error.graphQLErrors || error.graphQLErrors.length === 0) {
      return null;
    }

    const err = error.graphQLErrors[0];
    console.log('extracting first error', JSON.stringify(err));

    return {
      message: err.message,
      code: err.code,
      path: err.path,
      stacktrace: err.extensions.exception.stacktrace,
      statusCode: err.extensions.exception.output.statusCode
    };

    /*
    {
       "request" : {
          "query" : "query GetCustomer($id: String!) {\n  getCustomer(id: $id) {\n    id\n    phone\n    address1\n    address2\n    city\n    state\n    zip\n    timezone\n    preferenceGoodRatings\n    preferenceSaveMoney\n    preferenceSpecialists\n    preferenceSupportLocal\n    ownerId\n    createdAt\n    updatedAt\n    version\n  }\n}",
          "variables" : {
             "id" : "owner"
          },
       },
       "response" : {
          "data" : {
             "getCustomer" : null
          },
          "errors" : [
             {
                "extensions" : {
                   "code" : "INTERNAL_SERVER_ERROR",
                   "exception" : {
                      "data" : null,
                      "isBoom" : true,
                      "isServer" : false,
                      "output" : {
                         "headers" : {},
                         "payload" : {
                            "error" : "Not Found",
                            "message" : "Unable to find existing record for this user",
                            "statusCode" : 404
                         },
                         "statusCode" : 404
                      },
                      "stacktrace" : [
                         "Error: Unable to find existing record for this user",
                         "    at GenericManager.getInternal (/Users/arimus/workspace/appbuilder-backend/src/apis/generics/generic.manager.ts:889:18)",
                         "    at runMicrotasks (<anonymous>)",
                         "    at processTicksAndRejections (node:internal/process/task_queues:96:5)",
                         "    at GenericManager.descriptor.value (/Users/arimus/workspace/appbuilder-backend/src/decorators.ts:54:22)",
                         "    at Object.<anonymous> (/Users/arimus/workspace/appbuilder-backend/src/apis/generics/generic.plugin.ts:1893:47)",
                         "    at /Users/arimus/workspace/appbuilder-backend/src/decorators.ts:145:12"
                      ]
                   }
                },
                "locations" : [
                   {
                      "column" : 3,
                      "line" : 2
                   }
                ],
                "message" : "Unable to find existing record for this user",
                "path" : [
                   "getCustomer"
                ]
             }
          ],
          "headers" : {},
          "status" : 200
       }
    }
   */
  }
}
