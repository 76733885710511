import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InjectLogger } from '../decorators';
import { Logger } from '../logger';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from '@apollo/client/utilities';
import {
  CreatePushRegistrationDocument,
  GetPushRegistrationDocument,
  PushRegistration,
  UpdatePushRegistrationDocument
} from '../../generated/graphql';
import to from 'await-to-js';
import { ErrorsUtil } from '../../errors.util';
import { AppSandbox } from '../app.sandbox';
import { take } from 'rxjs/operators';

@Injectable()
export class PushMessagingService {
  @InjectLogger() logger: Logger;
  pushRegistrationQueue = new BehaviorSubject<string>(null);
  initialized = false;
  savedPushToken = null;

  constructor(private apollo: Apollo, private sandbox: AppSandbox) {
    this.setupPushRegistration().then(() => {
      // if we get an auth token (e.g. we logged in)
      this.sandbox.authToken$.subscribe(async authToken => {
        if (!!this.savedPushToken && !!authToken) {
          // we weren't logged in, so we have a saved push token, so now process it
          this.logger.debug('constructor(): processing saved push token', this.savedPushToken);
          await this.processPushToken(this.savedPushToken);
          this.savedPushToken = null;
        }
      });
    });
  }

  async queuePushRegistration(token: string): Promise<void> {
    this.logger.debug('queuePushRegistration()', token);
    this.pushRegistrationQueue.next(token);
  }

  async getPushRegistration(): Promise<PushRegistration> {
    this.logger.debug('getPushRegistration()');

    const [err, res] = await to(
      this.apollo
        .query<any>({
          query: GetPushRegistrationDocument,
          variables: {
            id: 'owner'
          },
          fetchPolicy: 'no-cache'
        })
        .toPromise()
    );

    if (err) {
      const firstError = ErrorsUtil.getFirstError(err);
      if (firstError.statusCode === 404) {
        this.logger.warn('getPushRegistration(): not found, returning null');
        return null;
      }

      this.logger.error('getPushRegistration(): error', err);
      throw err;
    }

    this.logger.debug('getPushRegistration(): results', res.data.getPushRegistration);
    return !!res.data.getPushRegistration ? cloneDeep(res.data.getPushRegistration) : null;
  }

  async createPushRegistration(token: string): Promise<void> {
    this.logger.debug('createPushRegistration()', token);

    const [error, res] = await to(
      this.apollo
        .query<any>({
          query: CreatePushRegistrationDocument,
          variables: {
            pushRegistration: {
              token
            },
            env: 'draft'
          },
          fetchPolicy: 'no-cache'
        })
        .toPromise()
    );

    if (error) {
      this.logger.error('createPushRegistration(): error', error);
      throw error;
    }

    this.logger.debug('createPushRegistration(): results', res.data.createPushRegistration);
    return !!res.data.createPushRegistration ? cloneDeep(res.data.createPushRegistration) : null;
  }

  async updatePushRegistration(token: string): Promise<void> {
    this.logger.debug('updatePushRegistration()', token);

    const [error, res] = await to(
      this.apollo
        .query<any>({
          query: UpdatePushRegistrationDocument,
          variables: {
            pushRegistration: {
              token
            }
          },
          fetchPolicy: 'no-cache'
        })
        .toPromise()
    );

    if (error) {
      this.logger.error('updatePushRegistration(): error', error);
      throw error;
    }
    this.logger.debug('updatePushRegistration(): results', res.data.updatePushRegistration);
    return !!res.data.updatePushRegistration ? cloneDeep(res.data.updatePushRegistration) : null;
  }

  async setupPushRegistration() {
    this.logger.debug('setupPushRegistration()');

    // try to drain push registration queue
    this.pushRegistrationQueue.subscribe(async token => {
      if (!!token) {
        this.logger.debug('setupPushRegistration(): not logged in yet, not registering push token until we are');

        const authToken = await this.sandbox.authToken$.pipe(take(1)).toPromise();

        if (!!authToken) {
          await this.processPushToken(token);
        } else {
          // save for later
          this.savedPushToken = token;
        }
      }
    });
  }

  async processPushToken(token: string) {
    const existingRegistration = await this.getPushRegistration();
    if (!!existingRegistration) {
      this.logger.debug('setupPushRegistration(): updating push registration token', token);
      await this.updatePushRegistration(token);
    } else {
      this.logger.debug('setupPushRegistration(): creating push registration token', token);
      await this.createPushRegistration(token);
    }
  }
}
