import { BehaviorSubject } from 'rxjs';
import { LocationData } from '../common.models';

export class Global {
  static title = 'Loadsmith';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static DEBUG_LOGGING = true;
  static DEBUG_LOGGING_OBJECTS = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static TRACE_LOGGING = true;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static CLOUD_LOGGING = true;

  static OVERRIDE_CURRENT_LOCATION: LocationData;

  static activity: BehaviorSubject<boolean> = new BehaviorSubject(false);

  static pushNotificationRecieved: BehaviorSubject<{
    operation: string;
    content: any;
  }> = new BehaviorSubject(null);

  static isWeb: BehaviorSubject<boolean> = new BehaviorSubject(null);
}
