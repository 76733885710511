import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Anything at all */
  Any: any;
  Date: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by
  * [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AuthenticationResult = {
  __typename?: 'AuthenticationResult';
  profile: UserProfile;
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type BlobsAndCount = {
  __typename?: 'BlobsAndCount';
  blobs: Array<GenericBlob>;
  count: Scalars['Int'];
};

export type BookedLoad = {
  __typename?: 'BookedLoad';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  booked?: Maybe<Scalars['Boolean']>;
  carrier?: Maybe<Scalars['Any']>;
  /** correlationId returned by mm reply topic egress */
  correlationId?: Maybe<Scalars['String']>;
  counterAmount?: Maybe<Scalars['Float']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  load?: Maybe<Scalars['Any']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** Whether or not we have received a reply on egress after API Post */
  mmReplyReceived?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  proofOfDeliveryDate?: Maybe<Scalars['Date']>;
  proofOfDeliveryImage?: Maybe<Scalars['Any']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  quoteAmount?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  trackingResponse?: Maybe<Scalars['JSONObject']>;
  trackingStatus?: Maybe<Scalars['JSONObject']>;
  type?: Maybe<BookedLoadTypeEnum>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type BookedLoadInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  accepted?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  booked?: InputMaybe<Scalars['Boolean']>;
  carrier?: InputMaybe<Scalars['Any']>;
  /** correlationId returned by mm reply topic egress */
  correlationId?: InputMaybe<Scalars['String']>;
  counterAmount?: InputMaybe<Scalars['Float']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  load?: InputMaybe<Scalars['Any']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not we have received a reply on egress after API Post */
  mmReplyReceived?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  proofOfDeliveryDate?: InputMaybe<Scalars['Date']>;
  proofOfDeliveryImage?: InputMaybe<Scalars['Any']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  quoteAmount?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  trackingResponse?: InputMaybe<Scalars['JSONObject']>;
  trackingStatus?: InputMaybe<Scalars['JSONObject']>;
  type?: InputMaybe<BookedLoadTypeEnum>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export enum BookedLoadTypeEnum {
  Book = 'Book',
  Quote = 'Quote'
}

export type BookingReply = {
  __typename?: 'BookingReply';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** The externally-defined code referencing the carrier with which the offer is associated. */
  carrierCode?: Maybe<Scalars['String']>;
  /** An internally-defined UUID referencing the carrier. */
  carrierID?: Maybe<Scalars['String']>;
  /** Reply Topic - The API request status code. */
  code?: Maybe<Scalars['String']>;
  /** The correlation ID provided back on the original request. */
  correlationId?: Maybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Reply Topic - The entity which the response relates to. */
  entity?: Maybe<Scalars['String']>;
  /** Reply Topic - the errors. */
  errors?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** The event that occurred. */
  eventType?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** Reply Topic - A message to describe the offer response. */
  message?: Maybe<Scalars['String']>;
  /** An internally-defined UUID referencing the offer. */
  mmId?: Maybe<Scalars['String']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** Entire Reply Body object */
  replyBody?: Maybe<Scalars['JSONObject']>;
  /** An internally-defined UUID referencing the route. */
  routeID?: Maybe<Scalars['String']>;
  /** The route number of the current route. */
  routeNumber?: Maybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type BookingReplyInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** The externally-defined code referencing the carrier with which the offer is associated. */
  carrierCode?: InputMaybe<Scalars['String']>;
  /** An internally-defined UUID referencing the carrier. */
  carrierID?: InputMaybe<Scalars['String']>;
  /** Reply Topic - The API request status code. */
  code?: InputMaybe<Scalars['String']>;
  /** The correlation ID provided back on the original request. */
  correlationId?: InputMaybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Reply Topic - The entity which the response relates to. */
  entity?: InputMaybe<Scalars['String']>;
  /** Reply Topic - the errors. */
  errors?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** The event that occurred. */
  eventType?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** Reply Topic - A message to describe the offer response. */
  message?: InputMaybe<Scalars['String']>;
  /** An internally-defined UUID referencing the offer. */
  mmId?: InputMaybe<Scalars['String']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** Entire Reply Body object */
  replyBody?: InputMaybe<Scalars['JSONObject']>;
  /** An internally-defined UUID referencing the route. */
  routeID?: InputMaybe<Scalars['String']>;
  /** The route number of the current route. */
  routeNumber?: InputMaybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type Carriers = {
  __typename?: 'Carriers';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** A collection of addresses associated with the Carrier. At least one address is required. */
  addresses?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** A collection of certifications associated with the Carrier. Reps are not required. */
  certifications?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** A unique ID exposed on the UI that can be searched throughout the TMS. */
  code: Scalars['String'];
  /** A collection of contacts associated with the Carrier. Contacts are not required. */
  contacts?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** User who created the record. */
  createdBy?: Maybe<Scalars['String']>;
  /** This represents the time when the record was created. */
  createdDateTime?: Maybe<Scalars['Date']>;
  /** This is where a carrier's 'doing business as' name will be displayed. Often used within Carrier Names registered with the DOT. */
  dbaName?: Maybe<Scalars['String']>;
  /** This is where a carrier's DOT Number will be displayed in the TMS. */
  dotNumber?: Maybe<Scalars['String']>;
  /** A collection of electronic tracking methods supported by Carrier */
  electronicTracking?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  externalStatus?: Maybe<Scalars['JSONObject']>;
  flattenedEmailAddresses?: Maybe<Scalars['String']>;
  flattenedPhoneNumbers?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** A collection of intrastates and geographies (country, states) in which the carrier operates? */
  geographySettings?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** A collection of keys associated with the Carrier to identify them throughout the clients organization. Identifiers are not req */
  identifiers?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** A collection of insurance coverages that are maintained by the Carrier. Insurance is not required for Carrier creat. only bookin */
  insurances?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  internalStatus?: Maybe<Scalars['JSONObject']>;
  isBinQualified?: Maybe<Scalars['Boolean']>;
  /** User who updated the record. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** This represents the time when the record was updated. */
  lastUpdatedDateTime?: Maybe<Scalars['Date']>;
  /** This is where a carrier's 'Tier' would be displayed (based on service, fleet size, insurance coverage, etc. */
  levelTerm?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  mainComplianceEmail?: Maybe<Scalars['String']>;
  manualTracking?: Maybe<Scalars['JSONObject']>;
  /** This is where the carrier's Motor Carrier Number would be displayed. */
  mcNumber?: Maybe<Scalars['String']>;
  /** A unique ID exposed on the UI that can be searched throughout the TMS. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** A name exposed on the UI that can be searched throughout the TMS. */
  name: Scalars['String'];
  /** This is where free form text entered by a user will be displayed. */
  notes?: Maybe<Scalars['String']>;
  operatingStatus?: Maybe<Scalars['String']>;
  /** Opportunity determined by fleet size, insurance coverage, loads booked by carrier rep, etc. Value from Carrier Oppt. Config */
  opportunityTerm?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** This is the Parent company of the carrier, if needed for payment. */
  parentCarrierCode?: Maybe<Scalars['String']>;
  /** This is the Parent company id of the carrier, if needed for payment. */
  parentCarrierId?: Maybe<Scalars['String']>;
  /** This is where a carrier phone number would be displayed under General Information. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** A collection of employees associated with the Carrier. Reps are not required. */
  reps?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** This is where a Carrier's current safety rating is indicated. */
  safetyRating?: Maybe<Scalars['String']>;
  /** This is where a carrier SCAC would be displayed in the TMS. */
  scacNumber?: Maybe<Scalars['String']>;
  /** A collection of services Record associated with the Carrier. services are not required. */
  services?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** The type of carrier (carrier, vendor, etc.). */
  typeTerm: Scalars['String'];
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
  /** Payment terms, Methods, Currency, remittance JSON */
  voucherSettings?: Maybe<Scalars['JSONObject']>;
  w9Certified?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
};

export type CarriersInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** A collection of addresses associated with the Carrier. At least one address is required. */
  addresses?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** A collection of certifications associated with the Carrier. Reps are not required. */
  certifications?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** A unique ID exposed on the UI that can be searched throughout the TMS. */
  code: Scalars['String'];
  /** A collection of contacts associated with the Carrier. Contacts are not required. */
  contacts?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** User who created the record. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** This represents the time when the record was created. */
  createdDateTime?: InputMaybe<Scalars['Date']>;
  /** This is where a carrier's 'doing business as' name will be displayed. Often used within Carrier Names registered with the DOT. */
  dbaName?: InputMaybe<Scalars['String']>;
  /** This is where a carrier's DOT Number will be displayed in the TMS. */
  dotNumber?: InputMaybe<Scalars['String']>;
  /** A collection of electronic tracking methods supported by Carrier */
  electronicTracking?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  externalStatus?: InputMaybe<Scalars['JSONObject']>;
  flattenedEmailAddresses?: InputMaybe<Scalars['String']>;
  flattenedPhoneNumbers?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** A collection of intrastates and geographies (country, states) in which the carrier operates? */
  geographySettings?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** A collection of keys associated with the Carrier to identify them throughout the clients organization. Identifiers are not req */
  identifiers?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** A collection of insurance coverages that are maintained by the Carrier. Insurance is not required for Carrier creat. only bookin */
  insurances?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  internalStatus?: InputMaybe<Scalars['JSONObject']>;
  isBinQualified?: InputMaybe<Scalars['Boolean']>;
  /** User who updated the record. */
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  /** This represents the time when the record was updated. */
  lastUpdatedDateTime?: InputMaybe<Scalars['Date']>;
  /** This is where a carrier's 'Tier' would be displayed (based on service, fleet size, insurance coverage, etc. */
  levelTerm?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  mainComplianceEmail?: InputMaybe<Scalars['String']>;
  manualTracking?: InputMaybe<Scalars['JSONObject']>;
  /** This is where the carrier's Motor Carrier Number would be displayed. */
  mcNumber?: InputMaybe<Scalars['String']>;
  /** A unique ID exposed on the UI that can be searched throughout the TMS. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** A name exposed on the UI that can be searched throughout the TMS. */
  name: Scalars['String'];
  /** This is where free form text entered by a user will be displayed. */
  notes?: InputMaybe<Scalars['String']>;
  operatingStatus?: InputMaybe<Scalars['String']>;
  /** Opportunity determined by fleet size, insurance coverage, loads booked by carrier rep, etc. Value from Carrier Oppt. Config */
  opportunityTerm?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** This is the Parent company of the carrier, if needed for payment. */
  parentCarrierCode?: InputMaybe<Scalars['String']>;
  /** This is the Parent company id of the carrier, if needed for payment. */
  parentCarrierId?: InputMaybe<Scalars['String']>;
  /** This is where a carrier phone number would be displayed under General Information. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** A collection of employees associated with the Carrier. Reps are not required. */
  reps?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** This is where a Carrier's current safety rating is indicated. */
  safetyRating?: InputMaybe<Scalars['String']>;
  /** This is where a carrier SCAC would be displayed in the TMS. */
  scacNumber?: InputMaybe<Scalars['String']>;
  /** A collection of services Record associated with the Carrier. services are not required. */
  services?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** The type of carrier (carrier, vendor, etc.). */
  typeTerm: Scalars['String'];
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
  /** Payment terms, Methods, Currency, remittance JSON */
  voucherSettings?: InputMaybe<Scalars['JSONObject']>;
  w9Certified?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CassInvoiceTracking = {
  __typename?: 'CassInvoiceTracking';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  invoiceNumber: Scalars['String'];
  invoiceSent: Scalars['Boolean'];
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  log?: Maybe<Scalars['String']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type CassInvoiceTrackingInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  invoiceNumber: Scalars['String'];
  invoiceSent: Scalars['Boolean'];
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  log?: InputMaybe<Scalars['String']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type DatCache = {
  __typename?: 'DATCache';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSONObject']>;
  destinationCity?: Maybe<Scalars['String']>;
  destinationState?: Maybe<Scalars['String']>;
  destinationZip?: Maybe<Scalars['String']>;
  equipmentType?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  originCity?: Maybe<Scalars['String']>;
  originState?: Maybe<Scalars['String']>;
  originZip?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type DatCacheInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  destinationCity?: InputMaybe<Scalars['String']>;
  destinationState?: InputMaybe<Scalars['String']>;
  destinationZip?: InputMaybe<Scalars['String']>;
  equipmentType?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  originCity?: InputMaybe<Scalars['String']>;
  originState?: InputMaybe<Scalars['String']>;
  originZip?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type GenericBlob = {
  __typename?: 'GenericBlob';
  archived?: Maybe<Scalars['Boolean']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  storageKey?: Maybe<Scalars['String']>;
  storageType?: Maybe<Scalars['String']>;
  synchronizationKey?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<GenericBlobTag>>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type GenericBlobInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  etag?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  lastModified?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  storageKey?: InputMaybe<Scalars['String']>;
  storageType?: InputMaybe<Scalars['String']>;
  synchronizationKey?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<GenericBlobTagInput>>;
  thumbnail?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type GenericBlobTag = {
  __typename?: 'GenericBlobTag';
  archived: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type GenericBlobTagInput = {
  archived?: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type GroupInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserInput>;
  ownerId?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<PermissionInput>>;
  roles?: InputMaybe<Array<RoleInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<Array<UserInput>>;
  version?: InputMaybe<Scalars['Int']>;
};

export type JwtDecoded = {
  __typename?: 'JwtDecoded';
  aud?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  exp: Scalars['Int'];
  iat?: Maybe<Scalars['Int']>;
  jti: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  pool: Scalars['JSONObject'];
  sub?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type KafkaErrorsQueue = {
  __typename?: 'KafkaErrorsQueue';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['String']>;
  /** This specifies that an entry that is has been removed from kafka and needs manual handling */
  committed?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSONObject']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorStack?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['Int']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  seenCount?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Date']>;
  topic?: Maybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type KafkaErrorsQueueInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  clientId?: InputMaybe<Scalars['String']>;
  /** This specifies that an entry that is has been removed from kafka and needs manual handling */
  committed?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  errorStack?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  partition?: InputMaybe<Scalars['Int']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  seenCount?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['Date']>;
  topic?: InputMaybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type Load = {
  __typename?: 'Load';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  allowedActions?: Maybe<Array<Maybe<LoadAllowedActionsEnum>>>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  bookingLink?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  companyDotNumber?: Maybe<Scalars['String']>;
  companyExternalKey?: Maybe<Scalars['String']>;
  companyMcNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  createdDate?: Maybe<Scalars['Date']>;
  displayMetadata?: Maybe<Scalars['JSONObject']>;
  distance?: Maybe<Scalars['Float']>;
  dropoffApptEarlyTime?: Maybe<Scalars['String']>;
  dropoffApptInst?: Maybe<Scalars['String']>;
  dropoffApptLateTime?: Maybe<Scalars['String']>;
  dropoffApptTime?: Maybe<Scalars['String']>;
  dropoffCity?: Maybe<Scalars['String']>;
  dropoffDate?: Maybe<Scalars['String']>;
  dropoffLat?: Maybe<Scalars['Float']>;
  dropoffLng?: Maybe<Scalars['Float']>;
  dropoffState?: Maybe<Scalars['String']>;
  dropoffZip?: Maybe<Scalars['String']>;
  dstTimezone?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  equipmentMetadata?: Maybe<Scalars['JSONObject']>;
  externalPostingId?: Maybe<Scalars['Int']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  hiddenDotNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  mmId?: Maybe<Scalars['String']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  offerExternalKey?: Maybe<Scalars['String']>;
  offerRate?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  ownerEmail?: Maybe<Scalars['String']>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  ownerPhone?: Maybe<Scalars['String']>;
  pickupApptEarlyTime?: Maybe<Scalars['String']>;
  pickupApptInst?: Maybe<Scalars['String']>;
  pickupApptLateTime?: Maybe<Scalars['String']>;
  pickupApptTime?: Maybe<Scalars['String']>;
  pickupCity?: Maybe<Scalars['String']>;
  pickupDate?: Maybe<Scalars['String']>;
  pickupLat?: Maybe<Scalars['Float']>;
  pickupLng?: Maybe<Scalars['Float']>;
  pickupState?: Maybe<Scalars['String']>;
  pickupZip?: Maybe<Scalars['String']>;
  postingUsername?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  routeMetadata?: Maybe<Scalars['JSONObject']>;
  srcDate?: Maybe<Scalars['String']>;
  srcTimezone?: Maybe<Scalars['String']>;
  stops?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  stopsOriginal?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['Date']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
  visibility?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export enum LoadAllowedActionsEnum {
  Book = 'Book',
  Quote = 'Quote'
}

export type LoadEvent = {
  __typename?: 'LoadEvent';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** User who created the record. */
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime?: Maybe<Scalars['Date']>;
  /** LOAD_CREATED_OR_UPDATED (should be enum but always the same) */
  eventType?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  hasActiveActivationStatus?: Maybe<Scalars['Boolean']>;
  hasCommodities?: Maybe<Scalars['Boolean']>;
  hasConfirmedDropoff?: Maybe<Scalars['Boolean']>;
  hasConfirmedPickup?: Maybe<Scalars['Boolean']>;
  hasMaxCost?: Maybe<Scalars['Boolean']>;
  hasOpenLifecycleStatus?: Maybe<Scalars['Boolean']>;
  hazmat?: Maybe<Scalars['Boolean']>;
  hrhv?: Maybe<Scalars['Boolean']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** User who updated the record. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedDateTime?: Maybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The internal id that references the load. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The external number that references the load. */
  number: Scalars['String'];
  /** The shipment of commodities from one point to another. */
  order?: Maybe<Scalars['JSONObject']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  route?: Maybe<Scalars['JSONObject']>;
  /** routeRecord Number primary number for load in loadboard */
  routeNumber?: Maybe<Scalars['String']>;
  routes?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** boolean representing all filters and active status defined by MM LS-248 determines whether to synch to load table */
  showOnLoadboard?: Maybe<Scalars['Boolean']>;
  /** The locations of the pick ups and deliveries for an order record. */
  stops?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type LoadEventInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** User who created the record. */
  createdBy?: InputMaybe<Scalars['String']>;
  createdDateTime?: InputMaybe<Scalars['Date']>;
  /** LOAD_CREATED_OR_UPDATED (should be enum but always the same) */
  eventType?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  hasActiveActivationStatus?: InputMaybe<Scalars['Boolean']>;
  hasCommodities?: InputMaybe<Scalars['Boolean']>;
  hasConfirmedDropoff?: InputMaybe<Scalars['Boolean']>;
  hasConfirmedPickup?: InputMaybe<Scalars['Boolean']>;
  hasMaxCost?: InputMaybe<Scalars['Boolean']>;
  hasOpenLifecycleStatus?: InputMaybe<Scalars['Boolean']>;
  hazmat?: InputMaybe<Scalars['Boolean']>;
  hrhv?: InputMaybe<Scalars['Boolean']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** User who updated the record. */
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  lastUpdatedDateTime?: InputMaybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The internal id that references the load. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The external number that references the load. */
  number: Scalars['String'];
  /** The shipment of commodities from one point to another. */
  order?: InputMaybe<Scalars['JSONObject']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  route?: InputMaybe<Scalars['JSONObject']>;
  /** routeRecord Number primary number for load in loadboard */
  routeNumber?: InputMaybe<Scalars['String']>;
  routes?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** boolean representing all filters and active status defined by MM LS-248 determines whether to synch to load table */
  showOnLoadboard?: InputMaybe<Scalars['Boolean']>;
  /** The locations of the pick ups and deliveries for an order record. */
  stops?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type LoadInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  allowedActions?: InputMaybe<Array<InputMaybe<LoadAllowedActionsEnum>>>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  bookingLink?: InputMaybe<Scalars['String']>;
  commodity?: InputMaybe<Scalars['String']>;
  companyDotNumber?: InputMaybe<Scalars['String']>;
  companyExternalKey?: InputMaybe<Scalars['String']>;
  companyMcNumber?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdDate?: InputMaybe<Scalars['Date']>;
  displayMetadata?: InputMaybe<Scalars['JSONObject']>;
  distance?: InputMaybe<Scalars['Float']>;
  dropoffApptEarlyTime?: InputMaybe<Scalars['String']>;
  dropoffApptInst?: InputMaybe<Scalars['String']>;
  dropoffApptLateTime?: InputMaybe<Scalars['String']>;
  dropoffApptTime?: InputMaybe<Scalars['String']>;
  dropoffCity?: InputMaybe<Scalars['String']>;
  dropoffDate?: InputMaybe<Scalars['String']>;
  dropoffLat?: InputMaybe<Scalars['Float']>;
  dropoffLng?: InputMaybe<Scalars['Float']>;
  dropoffState?: InputMaybe<Scalars['String']>;
  dropoffZip?: InputMaybe<Scalars['String']>;
  dstTimezone?: InputMaybe<Scalars['String']>;
  equipment?: InputMaybe<Scalars['String']>;
  equipmentMetadata?: InputMaybe<Scalars['JSONObject']>;
  externalPostingId?: InputMaybe<Scalars['Int']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  hiddenDotNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  mmId?: InputMaybe<Scalars['String']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  offerExternalKey?: InputMaybe<Scalars['String']>;
  offerRate?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  ownerEmail?: InputMaybe<Scalars['String']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  ownerPhone?: InputMaybe<Scalars['String']>;
  pickupApptEarlyTime?: InputMaybe<Scalars['String']>;
  pickupApptInst?: InputMaybe<Scalars['String']>;
  pickupApptLateTime?: InputMaybe<Scalars['String']>;
  pickupApptTime?: InputMaybe<Scalars['String']>;
  pickupCity?: InputMaybe<Scalars['String']>;
  pickupDate?: InputMaybe<Scalars['String']>;
  pickupLat?: InputMaybe<Scalars['Float']>;
  pickupLng?: InputMaybe<Scalars['Float']>;
  pickupState?: InputMaybe<Scalars['String']>;
  pickupZip?: InputMaybe<Scalars['String']>;
  postingUsername?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  referenceId?: InputMaybe<Scalars['String']>;
  routeMetadata?: InputMaybe<Scalars['JSONObject']>;
  srcDate?: InputMaybe<Scalars['String']>;
  srcTimezone?: InputMaybe<Scalars['String']>;
  stops?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  stopsOriginal?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedDate?: InputMaybe<Scalars['Date']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
  visibility?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** The model */
export type Model = {
  __typename?: 'Model';
  /** Whether or not the item is archived */
  archived: Scalars['Boolean'];
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Description for the item */
  description?: Maybe<Scalars['String']>;
  /** ID for the item */
  id: Scalars['String'];
  /** Import session key for the item */
  importSessionKey?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked: Scalars['Boolean'];
  /** name for the item */
  name: Scalars['String'];
  /** Old name for the item (used on rename) */
  oldName?: Maybe<Scalars['String']>;
  /** Options for the item */
  options?: Maybe<Scalars['JSONObject']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** Plural name for the item */
  pluralName?: Maybe<Scalars['String']>;
  /** Properties for the item */
  properties?: Maybe<Array<Maybe<ModelProperty>>>;
  /** Whether or not the item is published */
  published: Scalars['Boolean'];
  /** Whether or not the item is single mode */
  singleMode?: Maybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version: Scalars['Int'];
};

export type ModelInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  archivedName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  importSessionKey?: InputMaybe<Scalars['String']>;
  internal?: InputMaybe<Scalars['Boolean']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  oldName?: InputMaybe<Scalars['String']>;
  onePerOwner?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Scalars['JSONObject']>;
  organization?: InputMaybe<OrganizationInput>;
  owner?: InputMaybe<UserInput>;
  ownerId?: InputMaybe<Scalars['String']>;
  pluralName?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<ModelPropertyInput>>;
  public?: InputMaybe<Scalars['Boolean']>;
  published?: InputMaybe<Scalars['Boolean']>;
  singleMode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** The model property */
export type ModelProperty = {
  __typename?: 'ModelProperty';
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Defauot value for this property */
  default?: Maybe<Scalars['String']>;
  /** Default property script, which is used to generate a default on create */
  defaultScript?: Maybe<Scalars['String']>;
  /** Model property description */
  description?: Maybe<Scalars['String']>;
  /** Model property displayName */
  displayName?: Maybe<Scalars['String']>;
  /** ID for the item */
  id: Scalars['String'];
  /** If the property is an import key for the object */
  importKey?: Maybe<Scalars['Boolean']>;
  /** If the property is a generated key */
  isGenerated?: Maybe<Scalars['Boolean']>;
  /** If the property is hidden */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** If the property is lazy loaded */
  isLazy?: Maybe<Scalars['Boolean']>;
  /** If the property is a nullable key */
  isNullable?: Maybe<Scalars['Boolean']>;
  /** If the property is a unique key */
  isUnique?: Maybe<Scalars['Boolean']>;
  /** Length of the field */
  length?: Maybe<Scalars['Int']>;
  /** Model for the item */
  model?: Maybe<Model>;
  /** Model ID for the item */
  modelId?: Maybe<Scalars['String']>;
  /** Model property group */
  modelPropertyGroup?: Maybe<ModelPropertyGroup>;
  /** Model property name */
  name?: Maybe<Scalars['String']>;
  /** Model property options */
  options?: Maybe<Scalars['JSONObject']>;
  /** Model property order */
  order?: Maybe<Scalars['Int']>;
  /** If the property represents the model */
  representsModel?: Maybe<Scalars['Boolean']>;
  /** Model property sub type */
  subType?: Maybe<Scalars['String']>;
  /** Model property type */
  type?: Maybe<ModelTypeEnum>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version: Scalars['Int'];
};

/** The model property group */
export type ModelPropertyGroup = {
  __typename?: 'ModelPropertyGroup';
  /** ID for the item */
  id: Scalars['String'];
  /** Name for the item */
  name: Scalars['String'];
  /** Order of the item group */
  order?: Maybe<Scalars['Int']>;
};

export type ModelPropertyGroupInput = {
  id?: InputMaybe<Scalars['String']>;
  modelId?: InputMaybe<Scalars['String']>;
  modelProperties?: InputMaybe<Array<ModelPropertyInput>>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type ModelPropertyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  default?: InputMaybe<Scalars['String']>;
  defaultScript?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  importKey?: InputMaybe<Scalars['Boolean']>;
  isGenerated?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isHiddenUnlessOwner?: InputMaybe<Scalars['Boolean']>;
  isHiddenUnlessOwnerOrRelated?: InputMaybe<Scalars['Boolean']>;
  isLazy?: InputMaybe<Scalars['Boolean']>;
  isNullable?: InputMaybe<Scalars['Boolean']>;
  isUnique?: InputMaybe<Scalars['Boolean']>;
  length?: InputMaybe<Scalars['Int']>;
  modelPropertyGroup?: InputMaybe<ModelPropertyGroupInput>;
  name: Scalars['String'];
  options?: InputMaybe<Scalars['JSONObject']>;
  order?: InputMaybe<Scalars['Int']>;
  representsModel?: InputMaybe<Scalars['Boolean']>;
  subType?: InputMaybe<Scalars['String']>;
  type: ModelTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** Model type enum */
export enum ModelTypeEnum {
  /** json */
  Blob = 'blob',
  Boolean = 'boolean',
  Datetime = 'datetime',
  Float = 'float',
  Integer = 'integer',
  Json = 'json',
  Relation = 'relation',
  String = 'string',
  Text = 'text',
  Timestamp = 'timestamp'
}

export type Mutation = {
  __typename?: 'Mutation';
  authenticate: AuthenticationResult;
  createBlob: GenericBlob;
  createBookedLoad?: Maybe<BookedLoad>;
  createBookingReply?: Maybe<BookingReply>;
  createCarriers?: Maybe<Carriers>;
  createCassInvoiceTracking?: Maybe<CassInvoiceTracking>;
  createDATCache?: Maybe<DatCache>;
  createKafkaErrorsQueue?: Maybe<KafkaErrorsQueue>;
  createLoad?: Maybe<Load>;
  createLoadEvent?: Maybe<LoadEvent>;
  createOAuthAccessToken?: Maybe<OAuthAccessToken>;
  createOAuthAuthorizationCode?: Maybe<OAuthAuthorizationCode>;
  createOAuthClient?: Maybe<OAuthClient>;
  createOAuthRefreshToken?: Maybe<OAuthRefreshToken>;
  createOfferReply?: Maybe<OfferReply>;
  createOffers?: Maybe<Offers>;
  createPreferences?: Maybe<Preferences>;
  createPushRegistration?: Maybe<PushRegistration>;
  createSavedSearch?: Maybe<SavedSearch>;
  createTbcInvoiceTracking?: Maybe<TbcInvoiceTracking>;
  createTest?: Maybe<Test>;
  createVendorEvent?: Maybe<VendorEvent>;
  deleteBlob?: Maybe<Scalars['Boolean']>;
  deleteBookedLoad: Scalars['Boolean'];
  deleteBookingReply: Scalars['Boolean'];
  deleteCarriers: Scalars['Boolean'];
  deleteCassInvoiceTracking: Scalars['Boolean'];
  deleteDATCache: Scalars['Boolean'];
  deleteKafkaErrorsQueue: Scalars['Boolean'];
  deleteLoad: Scalars['Boolean'];
  deleteLoadEvent: Scalars['Boolean'];
  deleteOAuthAccessToken: Scalars['Boolean'];
  deleteOAuthAuthorizationCode: Scalars['Boolean'];
  deleteOAuthClient: Scalars['Boolean'];
  deleteOAuthRefreshToken: Scalars['Boolean'];
  deleteOfferReply: Scalars['Boolean'];
  deleteOffers: Scalars['Boolean'];
  deletePreferences: Scalars['Boolean'];
  deletePushRegistration: Scalars['Boolean'];
  deleteSavedSearch: Scalars['Boolean'];
  deleteTbcInvoiceTracking: Scalars['Boolean'];
  deleteTest: Scalars['Boolean'];
  deleteVendorEvent: Scalars['Boolean'];
  passwordlessAuthenticate: AuthenticationResult;
  passwordlessOTP: Scalars['Boolean'];
  publishBookedLoad?: Maybe<BookedLoad>;
  publishBookingReply?: Maybe<BookingReply>;
  publishCarriers?: Maybe<Carriers>;
  publishCassInvoiceTracking?: Maybe<CassInvoiceTracking>;
  publishDATCache?: Maybe<DatCache>;
  publishKafkaErrorsQueue?: Maybe<KafkaErrorsQueue>;
  publishLoad?: Maybe<Load>;
  publishLoadEvent?: Maybe<LoadEvent>;
  publishOAuthAccessToken?: Maybe<OAuthAccessToken>;
  publishOAuthAuthorizationCode?: Maybe<OAuthAuthorizationCode>;
  publishOAuthClient?: Maybe<OAuthClient>;
  publishOAuthRefreshToken?: Maybe<OAuthRefreshToken>;
  publishOfferReply?: Maybe<OfferReply>;
  publishOffers?: Maybe<Offers>;
  publishPreferences?: Maybe<Preferences>;
  publishPushRegistration?: Maybe<PushRegistration>;
  publishSavedSearch?: Maybe<SavedSearch>;
  publishTbcInvoiceTracking?: Maybe<TbcInvoiceTracking>;
  publishTest?: Maybe<Test>;
  publishVendorEvent?: Maybe<VendorEvent>;
  refreshToken: Scalars['String'];
  undeleteBlob?: Maybe<Scalars['Boolean']>;
  updateBlob?: Maybe<GenericBlob>;
  updateBookedLoad?: Maybe<BookedLoad>;
  updateBookingReply?: Maybe<BookingReply>;
  updateCarriers?: Maybe<Carriers>;
  updateCassInvoiceTracking?: Maybe<CassInvoiceTracking>;
  updateDATCache?: Maybe<DatCache>;
  updateKafkaErrorsQueue?: Maybe<KafkaErrorsQueue>;
  updateLoad?: Maybe<Load>;
  updateLoadEvent?: Maybe<LoadEvent>;
  updateOAuthAccessToken?: Maybe<OAuthAccessToken>;
  updateOAuthAuthorizationCode?: Maybe<OAuthAuthorizationCode>;
  updateOAuthClient?: Maybe<OAuthClient>;
  updateOAuthRefreshToken?: Maybe<OAuthRefreshToken>;
  updateOfferReply?: Maybe<OfferReply>;
  updateOffers?: Maybe<Offers>;
  updatePreferences?: Maybe<Preferences>;
  updatePushRegistration?: Maybe<PushRegistration>;
  updateSavedSearch?: Maybe<SavedSearch>;
  updateTbcInvoiceTracking?: Maybe<TbcInvoiceTracking>;
  updateTest?: Maybe<Test>;
  updateVendorEvent?: Maybe<VendorEvent>;
  validateToken: JwtDecoded;
};


export type MutationAuthenticateArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBlobArgs = {
  blob: GenericBlobInput;
  model?: InputMaybe<ModelInput>;
  orgIdOrName: Scalars['String'];
};


export type MutationCreateBookedLoadArgs = {
  bookedLoad: BookedLoadInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateBookingReplyArgs = {
  bookingReply: BookingReplyInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateCarriersArgs = {
  carriers: CarriersInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateCassInvoiceTrackingArgs = {
  cassInvoiceTracking: CassInvoiceTrackingInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateDatCacheArgs = {
  dATCache: DatCacheInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateKafkaErrorsQueueArgs = {
  env?: InputMaybe<Scalars['String']>;
  kafkaErrorsQueue: KafkaErrorsQueueInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateLoadArgs = {
  env?: InputMaybe<Scalars['String']>;
  load: LoadInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateLoadEventArgs = {
  env?: InputMaybe<Scalars['String']>;
  loadEvent: LoadEventInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOAuthAccessTokenArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthAccessToken: OAuthAccessTokenInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOAuthAuthorizationCodeArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthAuthorizationCode: OAuthAuthorizationCodeInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOAuthClientArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthClient: OAuthClientInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOAuthRefreshTokenArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthRefreshToken: OAuthRefreshTokenInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOfferReplyArgs = {
  env?: InputMaybe<Scalars['String']>;
  offerReply: OfferReplyInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOffersArgs = {
  env?: InputMaybe<Scalars['String']>;
  offers: OffersInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreatePreferencesArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  preferences: PreferencesInput;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreatePushRegistrationArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  pushRegistration: PushRegistrationInput;
};


export type MutationCreateSavedSearchArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  savedSearch: SavedSearchInput;
};


export type MutationCreateTbcInvoiceTrackingArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  tbcInvoiceTracking: TbcInvoiceTrackingInput;
};


export type MutationCreateTestArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  test: TestInput;
};


export type MutationCreateVendorEventArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  vendorEvent: VendorEventInput;
};


export type MutationDeleteBlobArgs = {
  blobId: Scalars['String'];
  hardDelete?: InputMaybe<Scalars['Boolean']>;
  orgIdOrName: Scalars['String'];
};


export type MutationDeleteBookedLoadArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteBookingReplyArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteCarriersArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteCassInvoiceTrackingArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteDatCacheArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteKafkaErrorsQueueArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteLoadArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteLoadEventArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteOAuthAccessTokenArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteOAuthAuthorizationCodeArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteOAuthClientArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteOAuthRefreshTokenArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteOfferReplyArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteOffersArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeletePreferencesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeletePushRegistrationArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteSavedSearchArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteTbcInvoiceTrackingArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteTestArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationDeleteVendorEventArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationPasswordlessAuthenticateArgs = {
  code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationPasswordlessOtpArgs = {
  callbackUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  poolName?: InputMaybe<Scalars['String']>;
};


export type MutationPublishBookedLoadArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishBookingReplyArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishCarriersArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishCassInvoiceTrackingArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishDatCacheArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishKafkaErrorsQueueArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishLoadArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishLoadEventArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishOAuthAccessTokenArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishOAuthAuthorizationCodeArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishOAuthClientArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishOAuthRefreshTokenArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishOfferReplyArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishOffersArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishPreferencesArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishPushRegistrationArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishSavedSearchArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishTbcInvoiceTrackingArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishTestArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationPublishVendorEventArgs = {
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationUndeleteBlobArgs = {
  blobId: Scalars['String'];
  orgIdOrName: Scalars['String'];
};


export type MutationUpdateBlobArgs = {
  blob: GenericBlobInput;
  orgIdOrName: Scalars['String'];
};


export type MutationUpdateBookedLoadArgs = {
  bookedLoad: BookedLoadInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBookingReplyArgs = {
  bookingReply: BookingReplyInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCarriersArgs = {
  carriers: CarriersInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCassInvoiceTrackingArgs = {
  cassInvoiceTracking: CassInvoiceTrackingInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateDatCacheArgs = {
  dATCache: DatCacheInput;
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateKafkaErrorsQueueArgs = {
  env?: InputMaybe<Scalars['String']>;
  kafkaErrorsQueue: KafkaErrorsQueueInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateLoadArgs = {
  env?: InputMaybe<Scalars['String']>;
  load: LoadInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateLoadEventArgs = {
  env?: InputMaybe<Scalars['String']>;
  loadEvent: LoadEventInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOAuthAccessTokenArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthAccessToken: OAuthAccessTokenInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOAuthAuthorizationCodeArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthAuthorizationCode: OAuthAuthorizationCodeInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOAuthClientArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthClient: OAuthClientInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOAuthRefreshTokenArgs = {
  env?: InputMaybe<Scalars['String']>;
  oAuthRefreshToken: OAuthRefreshTokenInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOfferReplyArgs = {
  env?: InputMaybe<Scalars['String']>;
  offerReply: OfferReplyInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOffersArgs = {
  env?: InputMaybe<Scalars['String']>;
  offers: OffersInput;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdatePreferencesArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  preferences: PreferencesInput;
  published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdatePushRegistrationArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  pushRegistration: PushRegistrationInput;
};


export type MutationUpdateSavedSearchArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  savedSearch: SavedSearchInput;
};


export type MutationUpdateTbcInvoiceTrackingArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  tbcInvoiceTracking: TbcInvoiceTrackingInput;
};


export type MutationUpdateTestArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  test: TestInput;
};


export type MutationUpdateVendorEventArgs = {
  env?: InputMaybe<Scalars['String']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  vendorEvent: VendorEventInput;
};


export type MutationValidateTokenArgs = {
  token: Scalars['String'];
};

export type OAuthAccessToken = {
  __typename?: 'OAuthAccessToken';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  accessToken: Scalars['String'];
  accessTokenExpiresAt: Scalars['Date'];
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  client: Scalars['Any'];
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<Scalars['String']>;
  refreshTokenExpiresAt?: Maybe<Scalars['Date']>;
  scope?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type OAuthAccessTokenInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  accessToken: Scalars['String'];
  accessTokenExpiresAt: Scalars['Date'];
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  client: Scalars['Any'];
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  extra?: InputMaybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  refreshTokenExpiresAt?: InputMaybe<Scalars['Date']>;
  scope?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type OAuthAuthorizationCode = {
  __typename?: 'OAuthAuthorizationCode';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  authorizationCode: Scalars['String'];
  client: Scalars['Any'];
  codeChallenge: Scalars['String'];
  codeChallengeMethod: Scalars['String'];
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['Date'];
  extra?: Maybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  redirectUri: Scalars['String'];
  scope?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type OAuthAuthorizationCodeInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  authorizationCode: Scalars['String'];
  client: Scalars['Any'];
  codeChallenge: Scalars['String'];
  codeChallengeMethod: Scalars['String'];
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt: Scalars['Date'];
  extra?: InputMaybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  redirectUri: Scalars['String'];
  scope?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type OAuthClient = {
  __typename?: 'OAuthClient';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  accessTokenLifetime: Scalars['Int'];
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  grants?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  redirectUris?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  refreshTokenLifetime?: Maybe<Scalars['Int']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type OAuthClientInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  accessTokenLifetime: Scalars['Int'];
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  extra?: InputMaybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  grants?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  redirectUris?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  refreshTokenLifetime?: InputMaybe<Scalars['Int']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type OAuthRefreshToken = {
  __typename?: 'OAuthRefreshToken';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  client: Scalars['Any'];
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Date'];
  scope?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type OAuthRefreshTokenInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  client: Scalars['Any'];
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  extra?: InputMaybe<Scalars['JSONObject']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Date'];
  scope?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type OfferReply = {
  __typename?: 'OfferReply';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** The externally-defined code referencing the carrier with which the offer is associated. */
  carrierCode?: Maybe<Scalars['String']>;
  /** An internally-defined UUID referencing the carrier. */
  carrierID?: Maybe<Scalars['String']>;
  /** Reply Topic - The API request status code. */
  code?: Maybe<Scalars['String']>;
  /** The correlation ID provided back on the original request. */
  correlationId?: Maybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Created datetime as linux epoch in milliseconds. */
  createdDateTime?: Maybe<Scalars['Date']>;
  /** Reply Topic - The entity which the response relates to. */
  entity?: Maybe<Scalars['String']>;
  /** Reply Topic - the errors. */
  errors?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** The event that occurred. */
  eventType?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Updated datetime as linux epoch in milliseconds. */
  lastUpdatedDateTime?: Maybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** Reply Topic - A message to describe the offer response. */
  message?: Maybe<Scalars['String']>;
  /** An internally-defined UUID referencing the offer. */
  mmId?: Maybe<Scalars['String']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** This represents the money record. */
  offerPrice?: Maybe<Scalars['Float']>;
  /** Currency term for offer price */
  offerPriceCurrency?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** Whole reply object */
  replyBody?: Maybe<Scalars['JSONObject']>;
  /** An internally-defined UUID referencing the route. */
  routeID?: Maybe<Scalars['String']>;
  /** The route number of the current route. */
  routeNumber?: Maybe<Scalars['String']>;
  /** The status of the offer being submitted.  Depending on your frontend design/UX it is recommended to default to Active Offer */
  typeTerm?: Maybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type OfferReplyInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** The externally-defined code referencing the carrier with which the offer is associated. */
  carrierCode?: InputMaybe<Scalars['String']>;
  /** An internally-defined UUID referencing the carrier. */
  carrierID?: InputMaybe<Scalars['String']>;
  /** Reply Topic - The API request status code. */
  code?: InputMaybe<Scalars['String']>;
  /** The correlation ID provided back on the original request. */
  correlationId?: InputMaybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Created datetime as linux epoch in milliseconds. */
  createdDateTime?: InputMaybe<Scalars['Date']>;
  /** Reply Topic - The entity which the response relates to. */
  entity?: InputMaybe<Scalars['String']>;
  /** Reply Topic - the errors. */
  errors?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** The event that occurred. */
  eventType?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Updated datetime as linux epoch in milliseconds. */
  lastUpdatedDateTime?: InputMaybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** Reply Topic - A message to describe the offer response. */
  message?: InputMaybe<Scalars['String']>;
  /** An internally-defined UUID referencing the offer. */
  mmId?: InputMaybe<Scalars['String']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** This represents the money record. */
  offerPrice?: InputMaybe<Scalars['Float']>;
  /** Currency term for offer price */
  offerPriceCurrency?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** Whole reply object */
  replyBody?: InputMaybe<Scalars['JSONObject']>;
  /** An internally-defined UUID referencing the route. */
  routeID?: InputMaybe<Scalars['String']>;
  /** The route number of the current route. */
  routeNumber?: InputMaybe<Scalars['String']>;
  /** The status of the offer being submitted.  Depending on your frontend design/UX it is recommended to default to Active Offer */
  typeTerm?: InputMaybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type Offers = {
  __typename?: 'Offers';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** The amount of money for the ask price. (currencycode,value) */
  askPrice?: Maybe<Scalars['JSONObject']>;
  /** The externally-defined code referencing the carrier with which the offer is associated. */
  carrierCode?: Maybe<Scalars['String']>;
  /** An internally-defined UUID referencing the carrier. */
  carrierID?: Maybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** UserId of the user who created the record. */
  createdBy?: Maybe<Scalars['String']>;
  /** Created datetime as linux epoch in milliseconds. */
  createdDateTime?: Maybe<Scalars['Date']>;
  /** Unix Timestamp (milliseconds). The empty datetime. */
  emptyDateTime?: Maybe<Scalars['Date']>;
  /** This indicates location where the truck will be empty. */
  emptyLocation?: Maybe<Scalars['JSONObject']>;
  /** The empty timezone. */
  emptyTimezone?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['JSONObject']>;
  /** The event that occurred. */
  eventType?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** UserId of the user who most recently updated the record. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Updated datetime as linux epoch in milliseconds. */
  lastUpdatedDateTime?: Maybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** An internally-defined UUID referencing the offer. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** Notes about the offer. */
  notes?: Maybe<Scalars['String']>;
  /** This represents the money record. */
  offerPrice?: Maybe<Scalars['JSONObject']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** Unit type and distance of the deadhead. */
  originDeadhead?: Maybe<Scalars['JSONObject']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** The offer reason term of the offer being submitted (OVER_MAX_COST, EQUIPMENT_TYPE, OTHER, SCHEDULING_CONFLICT) */
  reasonTerm?: Maybe<Scalars['String']>;
  /** offer response object from MMUI only */
  response?: Maybe<Scalars['JSONObject']>;
  /** An internally-defined UUID referencing the route. */
  routeID: Scalars['String'];
  /** The route number of the current route. */
  routeNumber: Scalars['String'];
  /** The source of the offer. (REP) */
  sourceTerm?: Maybe<Scalars['String']>;
  /** The status of the offer. (active, inactive) */
  statusTerm?: Maybe<Scalars['String']>;
  /** The status of the offer being submitted.  Depending on your frontend design/UX it is recommended to default to Active Offer */
  typeTerm: Scalars['String'];
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type OffersInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** The amount of money for the ask price. (currencycode,value) */
  askPrice?: InputMaybe<Scalars['JSONObject']>;
  /** The externally-defined code referencing the carrier with which the offer is associated. */
  carrierCode?: InputMaybe<Scalars['String']>;
  /** An internally-defined UUID referencing the carrier. */
  carrierID?: InputMaybe<Scalars['String']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** UserId of the user who created the record. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** Created datetime as linux epoch in milliseconds. */
  createdDateTime?: InputMaybe<Scalars['Date']>;
  /** Unix Timestamp (milliseconds). The empty datetime. */
  emptyDateTime?: InputMaybe<Scalars['Date']>;
  /** This indicates location where the truck will be empty. */
  emptyLocation?: InputMaybe<Scalars['JSONObject']>;
  /** The empty timezone. */
  emptyTimezone?: InputMaybe<Scalars['String']>;
  equipment?: InputMaybe<Scalars['JSONObject']>;
  /** The event that occurred. */
  eventType?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** UserId of the user who most recently updated the record. */
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  /** Updated datetime as linux epoch in milliseconds. */
  lastUpdatedDateTime?: InputMaybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** An internally-defined UUID referencing the offer. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** Notes about the offer. */
  notes?: InputMaybe<Scalars['String']>;
  /** This represents the money record. */
  offerPrice?: InputMaybe<Scalars['JSONObject']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** Unit type and distance of the deadhead. */
  originDeadhead?: InputMaybe<Scalars['JSONObject']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** The offer reason term of the offer being submitted (OVER_MAX_COST, EQUIPMENT_TYPE, OTHER, SCHEDULING_CONFLICT) */
  reasonTerm?: InputMaybe<Scalars['String']>;
  /** offer response object from MMUI only */
  response?: InputMaybe<Scalars['JSONObject']>;
  /** An internally-defined UUID referencing the route. */
  routeID: Scalars['String'];
  /** The route number of the current route. */
  routeNumber: Scalars['String'];
  /** The source of the offer. (REP) */
  sourceTerm?: InputMaybe<Scalars['String']>;
  /** The status of the offer. (active, inactive) */
  statusTerm?: InputMaybe<Scalars['String']>;
  /** The status of the offer being submitted.  Depending on your frontend design/UX it is recommended to default to Active Offer */
  typeTerm: Scalars['String'];
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

/** The organization */
export type Organization = {
  __typename?: 'Organization';
  /** ID for the item */
  id: Scalars['String'];
  /** Name for the item */
  name: Scalars['String'];
};

export type OrganizationInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  owner?: InputMaybe<UserInput>;
  ownerId?: InputMaybe<Scalars['String']>;
  tokens?: InputMaybe<Array<TokenInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type PermissionInput = {
  access?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  fieldset?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<GroupInput>;
  id?: InputMaybe<Scalars['String']>;
  implies?: InputMaybe<Scalars['Boolean']>;
  instanceId?: InputMaybe<Scalars['String']>;
  instanceType?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationInput>;
  permission?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userPool?: InputMaybe<UserPoolInput>;
};

export type Preferences = {
  __typename?: 'Preferences';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  cities?: Maybe<Scalars['JSONObject']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  dropoffFilter?: Maybe<Scalars['JSONObject']>;
  dropoffFilterEnabled?: Maybe<Scalars['Boolean']>;
  dropoffFilterMaxDistance?: Maybe<Scalars['Int']>;
  equipmentTypes?: Maybe<Array<Maybe<PreferencesEquipmentTypesEnum>>>;
  equipmentTypesEnabled?: Maybe<Scalars['Boolean']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  maxDistance?: Maybe<Scalars['Int']>;
  maxDistanceEnabled?: Maybe<Scalars['Boolean']>;
  maxWeight?: Maybe<Scalars['Int']>;
  maxWeightEnabled?: Maybe<Scalars['Boolean']>;
  minDistance?: Maybe<Scalars['Int']>;
  minDistanceEnabled?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  pickupEndDate?: Maybe<Scalars['Date']>;
  pickupEndDateEnabled?: Maybe<Scalars['Boolean']>;
  pickupFilter?: Maybe<Scalars['JSONObject']>;
  pickupFilterEnabled?: Maybe<Scalars['Boolean']>;
  pickupFilterMaxDistance?: Maybe<Scalars['Int']>;
  pickupStartDate?: Maybe<Scalars['Date']>;
  pickupStartDateEnabled?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  showBookableOnly?: Maybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export enum PreferencesEquipmentTypesEnum {
  FlatBed = 'FLAT_BED',
  OpenTop_20Ft = 'OPEN_TOP_20FT',
  Reefer = 'REEFER',
  Van = 'VAN'
}

export type PreferencesInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  cities?: InputMaybe<Scalars['JSONObject']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dropoffFilter?: InputMaybe<Scalars['JSONObject']>;
  dropoffFilterEnabled?: InputMaybe<Scalars['Boolean']>;
  dropoffFilterMaxDistance?: InputMaybe<Scalars['Int']>;
  equipmentTypes?: InputMaybe<Array<InputMaybe<PreferencesEquipmentTypesEnum>>>;
  equipmentTypesEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  maxDistance?: InputMaybe<Scalars['Int']>;
  maxDistanceEnabled?: InputMaybe<Scalars['Boolean']>;
  maxWeight?: InputMaybe<Scalars['Int']>;
  maxWeightEnabled?: InputMaybe<Scalars['Boolean']>;
  minDistance?: InputMaybe<Scalars['Int']>;
  minDistanceEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  pickupEndDate?: InputMaybe<Scalars['Date']>;
  pickupEndDateEnabled?: InputMaybe<Scalars['Boolean']>;
  pickupFilter?: InputMaybe<Scalars['JSONObject']>;
  pickupFilterEnabled?: InputMaybe<Scalars['Boolean']>;
  pickupFilterMaxDistance?: InputMaybe<Scalars['Int']>;
  pickupStartDate?: InputMaybe<Scalars['Date']>;
  pickupStartDateEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  showBookableOnly?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type PushRegistration = {
  __typename?: 'PushRegistration';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type PushRegistrationInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  countBlobs: BlobsAndCount;
  countBookedLoad?: Maybe<Scalars['Any']>;
  countBookingReply?: Maybe<Scalars['Any']>;
  countCarriers?: Maybe<Scalars['Any']>;
  countCassInvoiceTracking?: Maybe<Scalars['Any']>;
  countDATCache?: Maybe<Scalars['Any']>;
  countKafkaErrorsQueue?: Maybe<Scalars['Any']>;
  countLoad?: Maybe<Scalars['Any']>;
  countLoadEvent?: Maybe<Scalars['Any']>;
  countOAuthAccessToken?: Maybe<Scalars['Any']>;
  countOAuthAuthorizationCode?: Maybe<Scalars['Any']>;
  countOAuthClient?: Maybe<Scalars['Any']>;
  countOAuthRefreshToken?: Maybe<Scalars['Any']>;
  countOfferReply?: Maybe<Scalars['Any']>;
  countOffers?: Maybe<Scalars['Any']>;
  countPreferences?: Maybe<Scalars['Any']>;
  countPushRegistration?: Maybe<Scalars['Any']>;
  countSavedSearch?: Maybe<Scalars['Any']>;
  countTbcInvoiceTracking?: Maybe<Scalars['Any']>;
  countTest?: Maybe<Scalars['Any']>;
  countVendorEvent?: Maybe<Scalars['Any']>;
  getBlob: GenericBlob;
  getBookedLoad?: Maybe<BookedLoad>;
  getBookingReply?: Maybe<BookingReply>;
  getCarriers?: Maybe<Carriers>;
  getCassInvoiceTracking?: Maybe<CassInvoiceTracking>;
  getDATCache?: Maybe<DatCache>;
  getKafkaErrorsQueue?: Maybe<KafkaErrorsQueue>;
  getLoad?: Maybe<Load>;
  getLoadEvent?: Maybe<LoadEvent>;
  getOAuthAccessToken?: Maybe<OAuthAccessToken>;
  getOAuthAuthorizationCode?: Maybe<OAuthAuthorizationCode>;
  getOAuthClient?: Maybe<OAuthClient>;
  getOAuthRefreshToken?: Maybe<OAuthRefreshToken>;
  getOfferReply?: Maybe<OfferReply>;
  getOffers?: Maybe<Offers>;
  getPreferences?: Maybe<Preferences>;
  getPushRegistration?: Maybe<PushRegistration>;
  getSavedSearch?: Maybe<SavedSearch>;
  getTbcInvoiceTracking?: Maybe<TbcInvoiceTracking>;
  getTest?: Maybe<Test>;
  getVendorEvent?: Maybe<VendorEvent>;
  listBlobs: BlobsAndCount;
  listBookedLoad?: Maybe<Array<Maybe<BookedLoad>>>;
  listBookingReply?: Maybe<Array<Maybe<BookingReply>>>;
  listCarriers?: Maybe<Array<Maybe<Carriers>>>;
  listCassInvoiceTracking?: Maybe<Array<Maybe<CassInvoiceTracking>>>;
  listDATCache?: Maybe<Array<Maybe<DatCache>>>;
  listKafkaErrorsQueue?: Maybe<Array<Maybe<KafkaErrorsQueue>>>;
  listLoad?: Maybe<Array<Maybe<Load>>>;
  listLoadEvent?: Maybe<Array<Maybe<LoadEvent>>>;
  listOAuthAccessToken?: Maybe<Array<Maybe<OAuthAccessToken>>>;
  listOAuthAuthorizationCode?: Maybe<Array<Maybe<OAuthAuthorizationCode>>>;
  listOAuthClient?: Maybe<Array<Maybe<OAuthClient>>>;
  listOAuthRefreshToken?: Maybe<Array<Maybe<OAuthRefreshToken>>>;
  listOfferReply?: Maybe<Array<Maybe<OfferReply>>>;
  listOffers?: Maybe<Array<Maybe<Offers>>>;
  listPreferences?: Maybe<Array<Maybe<Preferences>>>;
  listPushRegistration?: Maybe<Array<Maybe<PushRegistration>>>;
  listSavedSearch?: Maybe<Array<Maybe<SavedSearch>>>;
  listTbcInvoiceTracking?: Maybe<Array<Maybe<TbcInvoiceTracking>>>;
  listTest?: Maybe<Array<Maybe<Test>>>;
  listVendorEvent?: Maybe<Array<Maybe<VendorEvent>>>;
};


export type QueryCountBlobsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  orgIdOrName: Scalars['String'];
  where?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryCountBookedLoadArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountBookingReplyArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountCarriersArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountCassInvoiceTrackingArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountDatCacheArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountKafkaErrorsQueueArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountLoadArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountLoadEventArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountOAuthAccessTokenArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountOAuthAuthorizationCodeArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountOAuthClientArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountOAuthRefreshTokenArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountOfferReplyArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountOffersArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountPreferencesArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountPushRegistrationArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountSavedSearchArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountTbcInvoiceTrackingArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountTestArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryCountVendorEventArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryGetBlobArgs = {
  blobId: Scalars['String'];
  decoded?: InputMaybe<Scalars['Boolean']>;
  includeData?: InputMaybe<Scalars['Boolean']>;
  includeThumbnailData?: InputMaybe<Scalars['Boolean']>;
  orgIdOrName: Scalars['String'];
};


export type QueryGetBookedLoadArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetBookingReplyArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetCarriersArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetCassInvoiceTrackingArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetDatCacheArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetKafkaErrorsQueueArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetLoadArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetLoadEventArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetOAuthAccessTokenArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetOAuthAuthorizationCodeArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetOAuthClientArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetOAuthRefreshTokenArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetOfferReplyArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetOffersArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetPreferencesArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetPushRegistrationArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetSavedSearchArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetTbcInvoiceTrackingArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetTestArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryGetVendorEventArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decode?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
};


export type QueryListBlobsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  decoded?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<Scalars['String']>>;
  includeData?: InputMaybe<Scalars['Boolean']>;
  includeThumbnailData?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orgIdOrName: Scalars['String'];
  sortByString?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryListBookedLoadArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListBookingReplyArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListCarriersArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListCassInvoiceTrackingArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListDatCacheArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListKafkaErrorsQueueArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListLoadArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListLoadEventArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListOAuthAccessTokenArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListOAuthAuthorizationCodeArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListOAuthClientArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListOAuthRefreshTokenArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListOfferReplyArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListOffersArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListPreferencesArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListPushRegistrationArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListSavedSearchArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListTbcInvoiceTrackingArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListTestArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};


export type QueryListVendorEventArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  env?: InputMaybe<Scalars['String']>;
  inlineBlob?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
  populateDeep?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  where?: InputMaybe<Scalars['Any']>;
};

export type RoleInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<GroupInput>>;
  id?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationInput>;
  owner?: InputMaybe<UserInput>;
  permissions?: InputMaybe<Array<PermissionInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<Array<UserInput>>;
  version?: InputMaybe<Scalars['Int']>;
};

export type SavedSearch = {
  __typename?: 'SavedSearch';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  dropoffEndDate?: Maybe<Scalars['Date']>;
  dropoffFilter?: Maybe<Scalars['JSONObject']>;
  dropoffFilterMaxDistance?: Maybe<Scalars['Int']>;
  dropoffStartDate?: Maybe<Scalars['Date']>;
  equipmentTypes?: Maybe<Array<Maybe<SavedSearchEquipmentTypesEnum>>>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  loadId?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  maxDistance?: Maybe<Scalars['Int']>;
  maxStops?: Maybe<Scalars['Int']>;
  maxWeight?: Maybe<Scalars['Int']>;
  minDistance?: Maybe<Scalars['Int']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  pickupEndDate?: Maybe<Scalars['Date']>;
  pickupFilter?: Maybe<Scalars['JSONObject']>;
  pickupFilterMaxDistance?: Maybe<Scalars['Int']>;
  pickupStartDate?: Maybe<Scalars['Date']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  showBookableOnly?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export enum SavedSearchEquipmentTypesEnum {
  FlatBed = 'FLAT_BED',
  OpenTop_20Ft = 'OPEN_TOP_20FT',
  Reefer = 'REEFER',
  Van = 'VAN'
}

export type SavedSearchInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dropoffEndDate?: InputMaybe<Scalars['Date']>;
  dropoffFilter?: InputMaybe<Scalars['JSONObject']>;
  dropoffFilterMaxDistance?: InputMaybe<Scalars['Int']>;
  dropoffStartDate?: InputMaybe<Scalars['Date']>;
  equipmentTypes?: InputMaybe<Array<InputMaybe<SavedSearchEquipmentTypesEnum>>>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  loadId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  maxDistance?: InputMaybe<Scalars['Int']>;
  maxStops?: InputMaybe<Scalars['Int']>;
  maxWeight?: InputMaybe<Scalars['Int']>;
  minDistance?: InputMaybe<Scalars['Int']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  pickupEndDate?: InputMaybe<Scalars['Date']>;
  pickupFilter?: InputMaybe<Scalars['JSONObject']>;
  pickupFilterMaxDistance?: InputMaybe<Scalars['Int']>;
  pickupStartDate?: InputMaybe<Scalars['Date']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  showBookableOnly?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type TbcInvoiceTracking = {
  __typename?: 'TbcInvoiceTracking';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** comma delimited documents found appended each run */
  documentsFound?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Invoice # including pre-generated 01 invoices from order # for AQP */
  invoiceNumber: Scalars['String'];
  /** Whether or not the Customer Invoice has been processed and uploaded to TBC */
  invoiceSent?: Maybe<Scalars['Boolean']>;
  /** Load number intentionally not linked to cached loads to keep this service narrow */
  loadNumber?: Maybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** processing log appended each run */
  log?: Maybe<Scalars['String']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** Order Number from MM */
  orderNumber?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
  /** A comma delimited list of vouchers sent to TBC for processing */
  vouchersSent?: Maybe<Scalars['String']>;
};

export type TbcInvoiceTrackingInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** comma delimited documents found appended each run */
  documentsFound?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Invoice # including pre-generated 01 invoices from order # for AQP */
  invoiceNumber: Scalars['String'];
  /** Whether or not the Customer Invoice has been processed and uploaded to TBC */
  invoiceSent?: InputMaybe<Scalars['Boolean']>;
  /** Load number intentionally not linked to cached loads to keep this service narrow */
  loadNumber?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** processing log appended each run */
  log?: InputMaybe<Scalars['String']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** Order Number from MM */
  orderNumber?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
  /** A comma delimited list of vouchers sent to TBC for processing */
  vouchersSent?: InputMaybe<Scalars['String']>;
};

export type Test = {
  __typename?: 'Test';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  field1?: Maybe<Scalars['String']>;
  field2?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Any']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  richText?: Maybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type TestInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  field1?: InputMaybe<Scalars['String']>;
  field2?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Any']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  richText?: InputMaybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type TokenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isRefreshToken?: InputMaybe<Scalars['Boolean']>;
  lastUsedAt?: InputMaybe<Scalars['DateTime']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserInput>;
  ownerId?: InputMaybe<Scalars['String']>;
  revoked?: InputMaybe<Scalars['Boolean']>;
  scope?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** The user */
export type User = {
  __typename?: 'User';
  /** ID for the user */
  id: Scalars['String'];
  /** Name for the user */
  name?: Maybe<Scalars['String']>;
};

export type UserInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  associatedTokens?: InputMaybe<Array<TokenInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<GroupInput>>;
  id?: InputMaybe<Scalars['String']>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastLoginCount?: InputMaybe<Scalars['Int']>;
  lastLoginIP?: InputMaybe<Scalars['String']>;
  lastPasswordReset?: InputMaybe<Scalars['DateTime']>;
  locale?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  oldEmail?: InputMaybe<Scalars['String']>;
  oldPhone?: InputMaybe<Scalars['String']>;
  organizations?: InputMaybe<Array<OrganizationInput>>;
  ownedTokens?: InputMaybe<Array<TokenInput>>;
  password?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<PermissionInput>>;
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  pool?: InputMaybe<UserPoolInput>;
  roles?: InputMaybe<Array<RoleInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UserPoolInput = {
  allowSignups?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissions?: InputMaybe<Array<PermissionInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<Array<UserInput>>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  email?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<UserProfileGroup>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  organizations?: Maybe<Array<UserProfileOrganization>>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserProfileRole>>;
};

export type UserProfileGroup = {
  __typename?: 'UserProfileGroup';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UserProfileOrganization = {
  __typename?: 'UserProfileOrganization';
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UserProfileRole = {
  __typename?: 'UserProfileRole';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type VendorEvent = {
  __typename?: 'VendorEvent';
  /** Environment for this item (draft | default) */
  _env?: Maybe<Scalars['String']>;
  /** Indicates whether this vendor has activeBounceDetailId. */
  activeBounceDetailId?: Maybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** A collection of associatedReps objects. */
  associatedReps?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** User id of the user who booked this record. */
  bookedById?: Maybe<Scalars['String']>;
  /** This indicates the contactId who booked the route vendor record. */
  bookedWithContactId?: Maybe<Scalars['String']>;
  /** A collection of bounceDetails for route vendor. */
  bounceDetails?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  brace?: Maybe<Scalars['JSONObject']>;
  /** The external code that references the carrier. */
  carrierCode?: Maybe<Scalars['String']>;
  /** The internal id that references the carrier. */
  carrierId?: Maybe<Scalars['String']>;
  /** costLineItems */
  costLineItems?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Date this item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** This is the id of the user who created the response. */
  createdBy?: Maybe<Scalars['String']>;
  /** This is the date time of when the response was created. */
  createdDateTime?: Maybe<Scalars['Date']>;
  /** This indicates distance (of vendor). Object of value, unitTerm */
  distance?: Maybe<Scalars['JSONObject']>;
  /** Equipment object. */
  equipment?: Maybe<Scalars['JSONObject']>;
  /** This is eventType for route_vendor. */
  eventType?: Maybe<Scalars['String']>;
  /** This indicates expectedEmptyDateTime. */
  expectedEmptyDateTime?: Maybe<Scalars['Date']>;
  /** This indicates expectedEmptyLocation. */
  expectedEmptyLocation?: Maybe<Scalars['JSONObject']>;
  /** None given (NONE, AWAITINGREQUIREMENTS) */
  financialStatus?: Maybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: Maybe<Scalars['String']>;
  /** ID for the item */
  id?: Maybe<Scalars['String']>;
  /** Indicates whether this vendor has been bounced. */
  isBounced?: Maybe<Scalars['Boolean']>;
  /** This represents financial disable or not */
  isFinancialDisable?: Maybe<Scalars['Boolean']>;
  /** This is the id of the user who last updated the response. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** This is the date time of the last update to the response. */
  lastUpdatedDateTime?: Maybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** A unique ID exposed on the UI that can be searched throughout the TMS. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: Maybe<Model>;
  /** The model ID for this item */
  modelId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** This is a customer defined order code associated with the route. */
  orderCode?: Maybe<Scalars['String']>;
  /** This is a globally unique order identifier associated with this route. */
  orderId?: Maybe<Scalars['String']>;
  /** This is the order number associated with the route. */
  orderNumber?: Maybe<Scalars['String']>;
  /** The organization for this item */
  organization?: Maybe<Organization>;
  /** The organization ID for this item */
  organizationId?: Maybe<Scalars['String']>;
  /** The owner for this item */
  owner?: Maybe<User>;
  /** The owner ID for this item */
  ownerId?: Maybe<Scalars['String']>;
  pallet?: Maybe<Scalars['JSONObject']>;
  /** This is where the pickupEtaDateTime would be displayed. */
  pickupEtaDateTime?: Maybe<Scalars['Date']>;
  /** This is where the end date of the pickupEtaTimezone would be displayed. */
  pickupEtaTimezone?: Maybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: Maybe<Scalars['Boolean']>;
  /** This is a collection of rateConfirmation objects */
  rateConfirmations?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  /** Whether or not the item is read only */
  readOnly?: Maybe<Scalars['Boolean']>;
  /** The internal id that references the route. */
  routeId?: Maybe<Scalars['String']>;
  /** The internally-generated friendly number that references the route. */
  routeNumber?: Maybe<Scalars['String']>;
  tarp?: Maybe<Scalars['JSONObject']>;
  /** Data Dictionary :This indicates typeTerm for route vendor. */
  typeTerm?: Maybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: Maybe<Scalars['Int']>;
};

export type VendorEventInput = {
  /** Environment for this item (draft | default) */
  _env?: InputMaybe<Scalars['String']>;
  /** Indicates whether this vendor has activeBounceDetailId. */
  activeBounceDetailId?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** A collection of associatedReps objects. */
  associatedReps?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** User id of the user who booked this record. */
  bookedById?: InputMaybe<Scalars['String']>;
  /** This indicates the contactId who booked the route vendor record. */
  bookedWithContactId?: InputMaybe<Scalars['String']>;
  /** A collection of bounceDetails for route vendor. */
  bounceDetails?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  brace?: InputMaybe<Scalars['JSONObject']>;
  /** The external code that references the carrier. */
  carrierCode?: InputMaybe<Scalars['String']>;
  /** The internal id that references the carrier. */
  carrierId?: InputMaybe<Scalars['String']>;
  /** costLineItems */
  costLineItems?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Date this item was created */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** This is the id of the user who created the response. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** This is the date time of when the response was created. */
  createdDateTime?: InputMaybe<Scalars['Date']>;
  /** This indicates distance (of vendor). Object of value, unitTerm */
  distance?: InputMaybe<Scalars['JSONObject']>;
  /** Equipment object. */
  equipment?: InputMaybe<Scalars['JSONObject']>;
  /** This is eventType for route_vendor. */
  eventType?: InputMaybe<Scalars['String']>;
  /** This indicates expectedEmptyDateTime. */
  expectedEmptyDateTime?: InputMaybe<Scalars['Date']>;
  /** This indicates expectedEmptyLocation. */
  expectedEmptyLocation?: InputMaybe<Scalars['JSONObject']>;
  /** None given (NONE, AWAITINGREQUIREMENTS) */
  financialStatus?: InputMaybe<Scalars['String']>;
  /** Generic ID for the item */
  genericId?: InputMaybe<Scalars['String']>;
  /** ID for the item */
  id?: InputMaybe<Scalars['String']>;
  /** Indicates whether this vendor has been bounced. */
  isBounced?: InputMaybe<Scalars['Boolean']>;
  /** This represents financial disable or not */
  isFinancialDisable?: InputMaybe<Scalars['Boolean']>;
  /** This is the id of the user who last updated the response. */
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  /** This is the date time of the last update to the response. */
  lastUpdatedDateTime?: InputMaybe<Scalars['Date']>;
  /** Whether or not the item is locked */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** A unique ID exposed on the UI that can be searched throughout the TMS. */
  mmId: Scalars['String'];
  /** The model for this item */
  model?: InputMaybe<Scalars['JSONObject']>;
  /** The model ID for this item */
  modelId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  /** This is a customer defined order code associated with the route. */
  orderCode?: InputMaybe<Scalars['String']>;
  /** This is a globally unique order identifier associated with this route. */
  orderId?: InputMaybe<Scalars['String']>;
  /** This is the order number associated with the route. */
  orderNumber?: InputMaybe<Scalars['String']>;
  /** The organization for this item */
  organization?: InputMaybe<Scalars['JSONObject']>;
  /** The organization ID for this item */
  organizationId?: InputMaybe<Scalars['String']>;
  /** The owner for this item */
  owner?: InputMaybe<Scalars['JSONObject']>;
  /** The owner ID for this item */
  ownerId?: InputMaybe<Scalars['String']>;
  pallet?: InputMaybe<Scalars['JSONObject']>;
  /** This is where the pickupEtaDateTime would be displayed. */
  pickupEtaDateTime?: InputMaybe<Scalars['Date']>;
  /** This is where the end date of the pickupEtaTimezone would be displayed. */
  pickupEtaTimezone?: InputMaybe<Scalars['String']>;
  /** Whether or not the item is published */
  published?: InputMaybe<Scalars['Boolean']>;
  /** This is a collection of rateConfirmation objects */
  rateConfirmations?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
  /** Whether or not the item is read only */
  readOnly?: InputMaybe<Scalars['Boolean']>;
  /** The internal id that references the route. */
  routeId?: InputMaybe<Scalars['String']>;
  /** The internally-generated friendly number that references the route. */
  routeNumber?: InputMaybe<Scalars['String']>;
  tarp?: InputMaybe<Scalars['JSONObject']>;
  /** Data Dictionary :This indicates typeTerm for route vendor. */
  typeTerm?: InputMaybe<Scalars['String']>;
  /** Date this item was updated */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version of this item. Incremented for every update */
  version?: InputMaybe<Scalars['Int']>;
};

export type AuthenticateMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
}>;


export type AuthenticateMutation = { __typename?: 'Mutation', authenticate: { __typename?: 'AuthenticationResult', token: string, refreshToken: string, profile: { __typename?: 'UserProfile', id: string, name?: string | null, email?: string | null, phone?: string | null, picture?: string | null, roles?: Array<{ __typename?: 'UserProfileRole', id: string, name: string }> | null, organizations?: Array<{ __typename?: 'UserProfileOrganization', id: string, name: string, displayName?: string | null }> | null, groups?: Array<{ __typename?: 'UserProfileGroup', id: string, name: string }> | null } } };

export type PasswordlessOtpMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  poolName: Scalars['String'];
  callbackUrl?: InputMaybe<Scalars['String']>;
}>;


export type PasswordlessOtpMutation = { __typename?: 'Mutation', passwordlessOTP: boolean };

export type PasswordlessAuthenticateMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type PasswordlessAuthenticateMutation = { __typename?: 'Mutation', passwordlessAuthenticate: { __typename?: 'AuthenticationResult', token: string, refreshToken: string, profile: { __typename?: 'UserProfile', id: string, name?: string | null, email?: string | null, phone?: string | null, picture?: string | null, roles?: Array<{ __typename?: 'UserProfileRole', id: string, name: string }> | null, organizations?: Array<{ __typename?: 'UserProfileOrganization', id: string, name: string, displayName?: string | null }> | null, groups?: Array<{ __typename?: 'UserProfileGroup', id: string, name: string }> | null } } };

export type ValidateTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidateTokenMutation = { __typename?: 'Mutation', validateToken: { __typename?: 'JwtDecoded', jti: string, user_id: string, sub?: string | null, name?: string | null, nickname?: string | null, picture?: string | null, email?: string | null, phone_number?: string | null, aud?: string | null, iat?: number | null, exp: number, type?: string | null, pool: any } };

export type ListAllBookedLoadsQueryVariables = Exact<{
  where?: InputMaybe<Scalars['Any']>;
  page?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  populate?: InputMaybe<Scalars['Boolean']>;
}>;


export type ListAllBookedLoadsQuery = { __typename?: 'Query', listBookedLoad?: Array<{ __typename?: 'BookedLoad', id?: string | null, type?: BookedLoadTypeEnum | null, rate?: number | null, quoteAmount?: number | null, load?: any | null, trackingStatus?: any | null, proofOfDeliveryDate?: any | null, proofOfDeliveryImage?: any | null, accepted?: boolean | null, booked?: boolean | null, counterAmount?: number | null, inactive?: boolean | null, createdAt?: any | null, organizationId?: string | null, updatedAt?: any | null } | null> | null };

export type GetBookedLoadQueryVariables = Exact<{
  id: Scalars['String'];
  populate?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetBookedLoadQuery = { __typename?: 'Query', getBookedLoad?: { __typename?: 'BookedLoad', id?: string | null, type?: BookedLoadTypeEnum | null, rate?: number | null, load?: any | null, trackingStatus?: any | null, proofOfDeliveryDate?: any | null, proofOfDeliveryImage?: any | null, accepted?: boolean | null, booked?: boolean | null, counterAmount?: number | null, inactive?: boolean | null, createdAt?: any | null, organizationId?: string | null, updatedAt?: any | null } | null };

export type CreateBookedLoadMutationVariables = Exact<{
  bookedLoad: BookedLoadInput;
  populate?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateBookedLoadMutation = { __typename?: 'Mutation', createBookedLoad?: { __typename?: 'BookedLoad', id?: string | null, type?: BookedLoadTypeEnum | null, rate?: number | null, load?: any | null, trackingStatus?: any | null, proofOfDeliveryDate?: any | null, proofOfDeliveryImage?: any | null, accepted?: boolean | null, booked?: boolean | null, counterAmount?: number | null, inactive?: boolean | null, createdAt?: any | null, organizationId?: string | null, updatedAt?: any | null } | null };

export type UpdateBookedLoadMutationVariables = Exact<{
  bookedLoad: BookedLoadInput;
  populate?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateBookedLoadMutation = { __typename?: 'Mutation', updateBookedLoad?: { __typename?: 'BookedLoad', id?: string | null, type?: BookedLoadTypeEnum | null, rate?: number | null, load?: any | null, trackingStatus?: any | null, proofOfDeliveryDate?: any | null, proofOfDeliveryImage?: any | null, accepted?: boolean | null, booked?: boolean | null, counterAmount?: number | null, inactive?: boolean | null, createdAt?: any | null, organizationId?: string | null, updatedAt?: any | null } | null };

export type DeleteBookedLoadMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteBookedLoadMutation = { __typename?: 'Mutation', deleteBookedLoad: boolean };

export type ListAllLoadsQueryVariables = Exact<{
  where?: InputMaybe<Scalars['Any']>;
  page?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ListAllLoadsQuery = { __typename?: 'Query', listLoad?: Array<{ __typename?: 'Load', id?: string | null, bookingLink?: string | null, commodity?: string | null, companyDotNumber?: string | null, companyExternalKey?: string | null, companyMcNumber?: string | null, companyName?: string | null, contactEmail?: string | null, contactName?: string | null, contactPhone?: string | null, createdAt?: any | null, createdDate?: any | null, distance?: number | null, dstTimezone?: string | null, equipment?: string | null, externalPostingId?: number | null, offerExternalKey?: string | null, offerRate?: string | null, organizationId?: string | null, postingUsername?: string | null, referenceId?: string | null, srcDate?: string | null, srcTimezone?: string | null, updatedAt?: any | null, updatedDate?: any | null, visibility?: string | null, stops?: Array<any | null> | null, weight?: number | null, pickupCity?: string | null, pickupState?: string | null, pickupZip?: string | null, pickupLat?: number | null, pickupLng?: number | null, pickupDate?: string | null, pickupApptInst?: string | null, pickupApptTime?: string | null, pickupApptEarlyTime?: string | null, pickupApptLateTime?: string | null, dropoffCity?: string | null, dropoffState?: string | null, dropoffZip?: string | null, dropoffLat?: number | null, dropoffLng?: number | null, dropoffDate?: string | null, dropoffApptInst?: string | null, dropoffApptTime?: string | null, dropoffApptEarlyTime?: string | null, dropoffApptLateTime?: string | null } | null> | null };

export type GetLoadQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetLoadQuery = { __typename?: 'Query', getLoad?: { __typename?: 'Load', id?: string | null, bookingLink?: string | null, commodity?: string | null, companyDotNumber?: string | null, companyExternalKey?: string | null, companyMcNumber?: string | null, companyName?: string | null, contactEmail?: string | null, contactName?: string | null, contactPhone?: string | null, createdAt?: any | null, createdDate?: any | null, distance?: number | null, dstTimezone?: string | null, equipment?: string | null, externalPostingId?: number | null, offerExternalKey?: string | null, offerRate?: string | null, organizationId?: string | null, postingUsername?: string | null, referenceId?: string | null, srcDate?: string | null, srcTimezone?: string | null, updatedAt?: any | null, updatedDate?: any | null, visibility?: string | null, stops?: Array<any | null> | null, weight?: number | null, pickupCity?: string | null, pickupState?: string | null, pickupZip?: string | null, pickupLat?: number | null, pickupLng?: number | null, pickupDate?: string | null, pickupApptInst?: string | null, pickupApptTime?: string | null, pickupApptEarlyTime?: string | null, pickupApptLateTime?: string | null, dropoffCity?: string | null, dropoffState?: string | null, dropoffZip?: string | null, dropoffLat?: number | null, dropoffLng?: number | null, dropoffDate?: string | null, dropoffApptInst?: string | null, dropoffApptTime?: string | null, dropoffApptEarlyTime?: string | null, dropoffApptLateTime?: string | null } | null };

export type GetPreferencesQueryVariables = Exact<{
  id: Scalars['String'];
  env?: InputMaybe<Scalars['String']>;
}>;


export type GetPreferencesQuery = { __typename?: 'Query', getPreferences?: { __typename?: 'Preferences', id?: string | null, ownerId?: string | null, cities?: any | null, minDistance?: number | null, minDistanceEnabled?: boolean | null, maxDistance?: number | null, maxDistanceEnabled?: boolean | null, maxWeight?: number | null, maxWeightEnabled?: boolean | null, equipmentTypes?: Array<PreferencesEquipmentTypesEnum | null> | null, equipmentTypesEnabled?: boolean | null, pickupFilter?: any | null, pickupFilterMaxDistance?: number | null, pickupFilterEnabled?: boolean | null, dropoffFilter?: any | null, dropoffFilterMaxDistance?: number | null, dropoffFilterEnabled?: boolean | null, pickupStartDate?: any | null, pickupStartDateEnabled?: boolean | null, pickupEndDate?: any | null, pickupEndDateEnabled?: boolean | null, showBookableOnly?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type CreatePreferencesMutationVariables = Exact<{
  preferences: PreferencesInput;
  env?: InputMaybe<Scalars['String']>;
}>;


export type CreatePreferencesMutation = { __typename?: 'Mutation', createPreferences?: { __typename?: 'Preferences', id?: string | null, ownerId?: string | null, cities?: any | null, minDistance?: number | null, minDistanceEnabled?: boolean | null, maxDistance?: number | null, maxDistanceEnabled?: boolean | null, maxWeight?: number | null, maxWeightEnabled?: boolean | null, equipmentTypes?: Array<PreferencesEquipmentTypesEnum | null> | null, equipmentTypesEnabled?: boolean | null, pickupFilter?: any | null, pickupFilterMaxDistance?: number | null, pickupFilterEnabled?: boolean | null, dropoffFilter?: any | null, dropoffFilterMaxDistance?: number | null, dropoffFilterEnabled?: boolean | null, pickupStartDate?: any | null, pickupStartDateEnabled?: boolean | null, pickupEndDate?: any | null, pickupEndDateEnabled?: boolean | null, showBookableOnly?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type UpdatePreferencesMutationVariables = Exact<{
  preferences: PreferencesInput;
}>;


export type UpdatePreferencesMutation = { __typename?: 'Mutation', updatePreferences?: { __typename?: 'Preferences', id?: string | null, ownerId?: string | null, cities?: any | null, minDistance?: number | null, minDistanceEnabled?: boolean | null, maxDistance?: number | null, maxDistanceEnabled?: boolean | null, maxWeight?: number | null, maxWeightEnabled?: boolean | null, equipmentTypes?: Array<PreferencesEquipmentTypesEnum | null> | null, equipmentTypesEnabled?: boolean | null, pickupFilter?: any | null, pickupFilterMaxDistance?: number | null, pickupFilterEnabled?: boolean | null, dropoffFilter?: any | null, dropoffFilterMaxDistance?: number | null, dropoffFilterEnabled?: boolean | null, pickupStartDate?: any | null, pickupStartDateEnabled?: boolean | null, pickupEndDate?: any | null, pickupEndDateEnabled?: boolean | null, showBookableOnly?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type GetPushRegistrationQueryVariables = Exact<{
  id: Scalars['String'];
  env?: InputMaybe<Scalars['String']>;
}>;


export type GetPushRegistrationQuery = { __typename?: 'Query', getPushRegistration?: { __typename?: 'PushRegistration', id?: string | null, token?: string | null, ownerId?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type CreatePushRegistrationMutationVariables = Exact<{
  pushRegistration: PushRegistrationInput;
  env?: InputMaybe<Scalars['String']>;
}>;


export type CreatePushRegistrationMutation = { __typename?: 'Mutation', createPushRegistration?: { __typename?: 'PushRegistration', id?: string | null, token?: string | null, ownerId?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type UpdatePushRegistrationMutationVariables = Exact<{
  pushRegistration: PushRegistrationInput;
}>;


export type UpdatePushRegistrationMutation = { __typename?: 'Mutation', updatePushRegistration?: { __typename?: 'PushRegistration', id?: string | null, token?: string | null, ownerId?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type ListAllSavedSearchQueryVariables = Exact<{
  where?: InputMaybe<Scalars['Any']>;
  page?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ListAllSavedSearchQuery = { __typename?: 'Query', listSavedSearch?: Array<{ __typename?: 'SavedSearch', id?: string | null, ownerId?: string | null, minDistance?: number | null, maxDistance?: number | null, maxWeight?: number | null, equipmentTypes?: Array<SavedSearchEquipmentTypesEnum | null> | null, pickupFilter?: any | null, pickupFilterMaxDistance?: number | null, dropoffFilter?: any | null, dropoffFilterMaxDistance?: number | null, dropoffStartDate?: any | null, pickupStartDate?: any | null, dropoffEndDate?: any | null, pickupEndDate?: any | null, maxStops?: number | null, showBookableOnly?: boolean | null, loadId?: string | null, title?: string | null, createdAt?: any | null, updatedAt?: any | null } | null> | null };

export type GetSavedSearchQueryVariables = Exact<{
  id: Scalars['String'];
  env?: InputMaybe<Scalars['String']>;
}>;


export type GetSavedSearchQuery = { __typename?: 'Query', getSavedSearch?: { __typename?: 'SavedSearch', id?: string | null, ownerId?: string | null, minDistance?: number | null, maxDistance?: number | null, maxWeight?: number | null, equipmentTypes?: Array<SavedSearchEquipmentTypesEnum | null> | null, pickupFilter?: any | null, pickupFilterMaxDistance?: number | null, dropoffFilter?: any | null, dropoffFilterMaxDistance?: number | null, dropoffStartDate?: any | null, pickupStartDate?: any | null, dropoffEndDate?: any | null, pickupEndDate?: any | null, maxStops?: number | null, showBookableOnly?: boolean | null, loadId?: string | null, title?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type CreateSavedSearchMutationVariables = Exact<{
  savedSearch: SavedSearchInput;
  env?: InputMaybe<Scalars['String']>;
}>;


export type CreateSavedSearchMutation = { __typename?: 'Mutation', createSavedSearch?: { __typename?: 'SavedSearch', id?: string | null, ownerId?: string | null, minDistance?: number | null, maxDistance?: number | null, maxWeight?: number | null, equipmentTypes?: Array<SavedSearchEquipmentTypesEnum | null> | null, pickupFilter?: any | null, pickupFilterMaxDistance?: number | null, dropoffFilter?: any | null, dropoffFilterMaxDistance?: number | null, dropoffStartDate?: any | null, pickupStartDate?: any | null, dropoffEndDate?: any | null, pickupEndDate?: any | null, maxStops?: number | null, showBookableOnly?: boolean | null, loadId?: string | null, title?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type UpdateSavedSearchMutationVariables = Exact<{
  savedSearch: SavedSearchInput;
}>;


export type UpdateSavedSearchMutation = { __typename?: 'Mutation', updateSavedSearch?: { __typename?: 'SavedSearch', id?: string | null, ownerId?: string | null, minDistance?: number | null, maxDistance?: number | null, maxWeight?: number | null, equipmentTypes?: Array<SavedSearchEquipmentTypesEnum | null> | null, pickupFilter?: any | null, pickupFilterMaxDistance?: number | null, dropoffStartDate?: any | null, pickupStartDate?: any | null, dropoffEndDate?: any | null, pickupEndDate?: any | null, maxStops?: number | null, showBookableOnly?: boolean | null, loadId?: string | null, title?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type DeleteSavedSearchMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSavedSearchMutation = { __typename?: 'Mutation', deleteSavedSearch: boolean };

export const AuthenticateDocument = gql`
    mutation Authenticate($email: String = null, $phone: String = null, $password: String!) {
  authenticate(email: $email, phone: $phone, password: $password) {
    token
    refreshToken
    profile {
      id
      name
      email
      phone
      picture
      roles {
        id
        name
      }
      organizations {
        id
        name
        displayName
      }
      groups {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthenticateGQL extends Apollo.Mutation<AuthenticateMutation, AuthenticateMutationVariables> {
    document = AuthenticateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PasswordlessOtpDocument = gql`
    mutation PasswordlessOTP($email: String = null, $phone: String = null, $name: String = null, $poolName: String!, $callbackUrl: String = null) {
  passwordlessOTP(
    email: $email
    phone: $phone
    name: $name
    poolName: $poolName
    callbackUrl: $callbackUrl
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PasswordlessOtpGQL extends Apollo.Mutation<PasswordlessOtpMutation, PasswordlessOtpMutationVariables> {
    document = PasswordlessOtpDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PasswordlessAuthenticateDocument = gql`
    mutation PasswordlessAuthenticate($email: String = null, $phone: String = null, $code: String!, $name: String = null) {
  passwordlessAuthenticate(email: $email, phone: $phone, code: $code, name: $name) {
    token
    refreshToken
    profile {
      id
      name
      email
      phone
      picture
      roles {
        id
        name
      }
      organizations {
        id
        name
        displayName
      }
      groups {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PasswordlessAuthenticateGQL extends Apollo.Mutation<PasswordlessAuthenticateMutation, PasswordlessAuthenticateMutationVariables> {
    document = PasswordlessAuthenticateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateTokenDocument = gql`
    mutation ValidateToken($token: String!) {
  validateToken(token: $token) {
    jti
    user_id
    sub
    name
    nickname
    picture
    email
    phone_number
    aud
    iat
    exp
    type
    pool
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateTokenGQL extends Apollo.Mutation<ValidateTokenMutation, ValidateTokenMutationVariables> {
    document = ValidateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAllBookedLoadsDocument = gql`
    query ListAllBookedLoads($where: Any, $page: Int = 0, $offset: Int = null, $limit: Int = 20, $populate: Boolean = true) {
  listBookedLoad(
    where: $where
    page: $page
    offset: $offset
    limit: $limit
    published: false
    populate: $populate
  ) {
    id
    type
    rate
    quoteAmount
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAllBookedLoadsGQL extends Apollo.Query<ListAllBookedLoadsQuery, ListAllBookedLoadsQueryVariables> {
    document = ListAllBookedLoadsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBookedLoadDocument = gql`
    query GetBookedLoad($id: String!, $populate: Boolean = false) {
  getBookedLoad(id: $id, populate: $populate) {
    id
    type
    rate
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookedLoadGQL extends Apollo.Query<GetBookedLoadQuery, GetBookedLoadQueryVariables> {
    document = GetBookedLoadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBookedLoadDocument = gql`
    mutation CreateBookedLoad($bookedLoad: BookedLoadInput!, $populate: Boolean = false) {
  createBookedLoad(bookedLoad: $bookedLoad, published: false, populate: $populate) {
    id
    type
    rate
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBookedLoadGQL extends Apollo.Mutation<CreateBookedLoadMutation, CreateBookedLoadMutationVariables> {
    document = CreateBookedLoadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookedLoadDocument = gql`
    mutation UpdateBookedLoad($bookedLoad: BookedLoadInput!, $populate: Boolean = false) {
  updateBookedLoad(bookedLoad: $bookedLoad, published: false, populate: $populate) {
    id
    type
    rate
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookedLoadGQL extends Apollo.Mutation<UpdateBookedLoadMutation, UpdateBookedLoadMutationVariables> {
    document = UpdateBookedLoadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBookedLoadDocument = gql`
    mutation DeleteBookedLoad($id: String!) {
  deleteBookedLoad(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBookedLoadGQL extends Apollo.Mutation<DeleteBookedLoadMutation, DeleteBookedLoadMutationVariables> {
    document = DeleteBookedLoadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAllLoadsDocument = gql`
    query ListAllLoads($where: Any, $page: Int = 0, $offset: Int = null, $limit: Int = 20) {
  listLoad(
    where: $where
    page: $page
    offset: $offset
    limit: $limit
    published: false
  ) {
    id
    bookingLink
    commodity
    companyDotNumber
    companyExternalKey
    companyMcNumber
    companyName
    contactEmail
    contactName
    contactPhone
    createdAt
    createdDate
    distance
    dstTimezone
    equipment
    externalPostingId
    offerExternalKey
    offerRate
    organizationId
    postingUsername
    referenceId
    srcDate
    srcTimezone
    updatedAt
    updatedDate
    visibility
    stops
    weight
    pickupCity
    pickupState
    pickupZip
    pickupLat
    pickupLng
    pickupDate
    pickupApptInst
    pickupApptTime
    pickupApptEarlyTime
    pickupApptLateTime
    dropoffCity
    dropoffState
    dropoffZip
    dropoffLat
    dropoffLng
    dropoffDate
    dropoffApptInst
    dropoffApptTime
    dropoffApptEarlyTime
    dropoffApptLateTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAllLoadsGQL extends Apollo.Query<ListAllLoadsQuery, ListAllLoadsQueryVariables> {
    document = ListAllLoadsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLoadDocument = gql`
    query GetLoad($id: String!) {
  getLoad(id: $id) {
    id
    bookingLink
    commodity
    companyDotNumber
    companyExternalKey
    companyMcNumber
    companyName
    contactEmail
    contactName
    contactPhone
    createdAt
    createdDate
    distance
    dstTimezone
    equipment
    externalPostingId
    offerExternalKey
    offerRate
    organizationId
    postingUsername
    referenceId
    srcDate
    srcTimezone
    updatedAt
    updatedDate
    visibility
    stops
    weight
    pickupCity
    pickupState
    pickupZip
    pickupLat
    pickupLng
    pickupDate
    pickupApptInst
    pickupApptTime
    pickupApptEarlyTime
    pickupApptLateTime
    dropoffCity
    dropoffState
    dropoffZip
    dropoffLat
    dropoffLng
    dropoffDate
    dropoffApptInst
    dropoffApptTime
    dropoffApptEarlyTime
    dropoffApptLateTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLoadGQL extends Apollo.Query<GetLoadQuery, GetLoadQueryVariables> {
    document = GetLoadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPreferencesDocument = gql`
    query GetPreferences($id: String!, $env: String) {
  getPreferences(id: $id, env: $env) {
    id
    ownerId
    cities
    minDistance
    minDistanceEnabled
    maxDistance
    maxDistanceEnabled
    maxWeight
    maxWeightEnabled
    equipmentTypes
    equipmentTypesEnabled
    pickupFilter
    pickupFilterMaxDistance
    pickupFilterEnabled
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffFilterEnabled
    pickupStartDate
    pickupStartDateEnabled
    pickupEndDate
    pickupEndDateEnabled
    showBookableOnly
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPreferencesGQL extends Apollo.Query<GetPreferencesQuery, GetPreferencesQueryVariables> {
    document = GetPreferencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePreferencesDocument = gql`
    mutation CreatePreferences($preferences: PreferencesInput!, $env: String) {
  createPreferences(preferences: $preferences, published: false, env: $env) {
    id
    ownerId
    cities
    minDistance
    minDistanceEnabled
    maxDistance
    maxDistanceEnabled
    maxWeight
    maxWeightEnabled
    equipmentTypes
    equipmentTypesEnabled
    pickupFilter
    pickupFilterMaxDistance
    pickupFilterEnabled
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffFilterEnabled
    pickupStartDate
    pickupStartDateEnabled
    pickupEndDate
    pickupEndDateEnabled
    showBookableOnly
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePreferencesGQL extends Apollo.Mutation<CreatePreferencesMutation, CreatePreferencesMutationVariables> {
    document = CreatePreferencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePreferencesDocument = gql`
    mutation UpdatePreferences($preferences: PreferencesInput!) {
  updatePreferences(preferences: $preferences) {
    id
    ownerId
    cities
    minDistance
    minDistanceEnabled
    maxDistance
    maxDistanceEnabled
    maxWeight
    maxWeightEnabled
    equipmentTypes
    equipmentTypesEnabled
    pickupFilter
    pickupFilterMaxDistance
    pickupFilterEnabled
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffFilterEnabled
    pickupStartDate
    pickupStartDateEnabled
    pickupEndDate
    pickupEndDateEnabled
    showBookableOnly
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePreferencesGQL extends Apollo.Mutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables> {
    document = UpdatePreferencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPushRegistrationDocument = gql`
    query GetPushRegistration($id: String!, $env: String) {
  getPushRegistration(id: $id, env: $env) {
    id
    token
    ownerId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPushRegistrationGQL extends Apollo.Query<GetPushRegistrationQuery, GetPushRegistrationQueryVariables> {
    document = GetPushRegistrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePushRegistrationDocument = gql`
    mutation CreatePushRegistration($pushRegistration: PushRegistrationInput!, $env: String) {
  createPushRegistration(pushRegistration: $pushRegistration, env: $env) {
    id
    token
    ownerId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePushRegistrationGQL extends Apollo.Mutation<CreatePushRegistrationMutation, CreatePushRegistrationMutationVariables> {
    document = CreatePushRegistrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePushRegistrationDocument = gql`
    mutation UpdatePushRegistration($pushRegistration: PushRegistrationInput!) {
  updatePushRegistration(pushRegistration: $pushRegistration) {
    id
    token
    ownerId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePushRegistrationGQL extends Apollo.Mutation<UpdatePushRegistrationMutation, UpdatePushRegistrationMutationVariables> {
    document = UpdatePushRegistrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAllSavedSearchDocument = gql`
    query ListAllSavedSearch($where: Any, $page: Int = 0, $offset: Int = null, $limit: Int = 20) {
  listSavedSearch(
    where: $where
    page: $page
    offset: $offset
    limit: $limit
    published: false
  ) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAllSavedSearchGQL extends Apollo.Query<ListAllSavedSearchQuery, ListAllSavedSearchQueryVariables> {
    document = ListAllSavedSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSavedSearchDocument = gql`
    query GetSavedSearch($id: String!, $env: String) {
  getSavedSearch(id: $id, env: $env) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSavedSearchGQL extends Apollo.Query<GetSavedSearchQuery, GetSavedSearchQueryVariables> {
    document = GetSavedSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSavedSearchDocument = gql`
    mutation CreateSavedSearch($savedSearch: SavedSearchInput!, $env: String) {
  createSavedSearch(savedSearch: $savedSearch, published: false, env: $env) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSavedSearchGQL extends Apollo.Mutation<CreateSavedSearchMutation, CreateSavedSearchMutationVariables> {
    document = CreateSavedSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSavedSearchDocument = gql`
    mutation UpdateSavedSearch($savedSearch: SavedSearchInput!) {
  updateSavedSearch(savedSearch: $savedSearch) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSavedSearchGQL extends Apollo.Mutation<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables> {
    document = UpdateSavedSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSavedSearchDocument = gql`
    mutation DeleteSavedSearch($id: String!) {
  deleteSavedSearch(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSavedSearchGQL extends Apollo.Mutation<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables> {
    document = DeleteSavedSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

export const Authenticate = gql`
    mutation Authenticate($email: String = null, $phone: String = null, $password: String!) {
  authenticate(email: $email, phone: $phone, password: $password) {
    token
    refreshToken
    profile {
      id
      name
      email
      phone
      picture
      roles {
        id
        name
      }
      organizations {
        id
        name
        displayName
      }
      groups {
        id
        name
      }
    }
  }
}
    `;
export const PasswordlessOtp = gql`
    mutation PasswordlessOTP($email: String = null, $phone: String = null, $name: String = null, $poolName: String!, $callbackUrl: String = null) {
  passwordlessOTP(
    email: $email
    phone: $phone
    name: $name
    poolName: $poolName
    callbackUrl: $callbackUrl
  )
}
    `;
export const PasswordlessAuthenticate = gql`
    mutation PasswordlessAuthenticate($email: String = null, $phone: String = null, $code: String!, $name: String = null) {
  passwordlessAuthenticate(email: $email, phone: $phone, code: $code, name: $name) {
    token
    refreshToken
    profile {
      id
      name
      email
      phone
      picture
      roles {
        id
        name
      }
      organizations {
        id
        name
        displayName
      }
      groups {
        id
        name
      }
    }
  }
}
    `;
export const ValidateToken = gql`
    mutation ValidateToken($token: String!) {
  validateToken(token: $token) {
    jti
    user_id
    sub
    name
    nickname
    picture
    email
    phone_number
    aud
    iat
    exp
    type
    pool
  }
}
    `;
export const ListAllBookedLoads = gql`
    query ListAllBookedLoads($where: Any, $page: Int = 0, $offset: Int = null, $limit: Int = 20, $populate: Boolean = true) {
  listBookedLoad(
    where: $where
    page: $page
    offset: $offset
    limit: $limit
    published: false
    populate: $populate
  ) {
    id
    type
    rate
    quoteAmount
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;
export const GetBookedLoad = gql`
    query GetBookedLoad($id: String!, $populate: Boolean = false) {
  getBookedLoad(id: $id, populate: $populate) {
    id
    type
    rate
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;
export const CreateBookedLoad = gql`
    mutation CreateBookedLoad($bookedLoad: BookedLoadInput!, $populate: Boolean = false) {
  createBookedLoad(bookedLoad: $bookedLoad, published: false, populate: $populate) {
    id
    type
    rate
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;
export const UpdateBookedLoad = gql`
    mutation UpdateBookedLoad($bookedLoad: BookedLoadInput!, $populate: Boolean = false) {
  updateBookedLoad(bookedLoad: $bookedLoad, published: false, populate: $populate) {
    id
    type
    rate
    load
    trackingStatus
    proofOfDeliveryDate
    proofOfDeliveryImage
    accepted
    booked
    counterAmount
    inactive
    createdAt
    organizationId
    updatedAt
  }
}
    `;
export const DeleteBookedLoad = gql`
    mutation DeleteBookedLoad($id: String!) {
  deleteBookedLoad(id: $id)
}
    `;
export const ListAllLoads = gql`
    query ListAllLoads($where: Any, $page: Int = 0, $offset: Int = null, $limit: Int = 20) {
  listLoad(
    where: $where
    page: $page
    offset: $offset
    limit: $limit
    published: false
  ) {
    id
    bookingLink
    commodity
    companyDotNumber
    companyExternalKey
    companyMcNumber
    companyName
    contactEmail
    contactName
    contactPhone
    createdAt
    createdDate
    distance
    dstTimezone
    equipment
    externalPostingId
    offerExternalKey
    offerRate
    organizationId
    postingUsername
    referenceId
    srcDate
    srcTimezone
    updatedAt
    updatedDate
    visibility
    stops
    weight
    pickupCity
    pickupState
    pickupZip
    pickupLat
    pickupLng
    pickupDate
    pickupApptInst
    pickupApptTime
    pickupApptEarlyTime
    pickupApptLateTime
    dropoffCity
    dropoffState
    dropoffZip
    dropoffLat
    dropoffLng
    dropoffDate
    dropoffApptInst
    dropoffApptTime
    dropoffApptEarlyTime
    dropoffApptLateTime
  }
}
    `;
export const GetLoad = gql`
    query GetLoad($id: String!) {
  getLoad(id: $id) {
    id
    bookingLink
    commodity
    companyDotNumber
    companyExternalKey
    companyMcNumber
    companyName
    contactEmail
    contactName
    contactPhone
    createdAt
    createdDate
    distance
    dstTimezone
    equipment
    externalPostingId
    offerExternalKey
    offerRate
    organizationId
    postingUsername
    referenceId
    srcDate
    srcTimezone
    updatedAt
    updatedDate
    visibility
    stops
    weight
    pickupCity
    pickupState
    pickupZip
    pickupLat
    pickupLng
    pickupDate
    pickupApptInst
    pickupApptTime
    pickupApptEarlyTime
    pickupApptLateTime
    dropoffCity
    dropoffState
    dropoffZip
    dropoffLat
    dropoffLng
    dropoffDate
    dropoffApptInst
    dropoffApptTime
    dropoffApptEarlyTime
    dropoffApptLateTime
  }
}
    `;
export const GetPreferences = gql`
    query GetPreferences($id: String!, $env: String) {
  getPreferences(id: $id, env: $env) {
    id
    ownerId
    cities
    minDistance
    minDistanceEnabled
    maxDistance
    maxDistanceEnabled
    maxWeight
    maxWeightEnabled
    equipmentTypes
    equipmentTypesEnabled
    pickupFilter
    pickupFilterMaxDistance
    pickupFilterEnabled
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffFilterEnabled
    pickupStartDate
    pickupStartDateEnabled
    pickupEndDate
    pickupEndDateEnabled
    showBookableOnly
    createdAt
    updatedAt
  }
}
    `;
export const CreatePreferences = gql`
    mutation CreatePreferences($preferences: PreferencesInput!, $env: String) {
  createPreferences(preferences: $preferences, published: false, env: $env) {
    id
    ownerId
    cities
    minDistance
    minDistanceEnabled
    maxDistance
    maxDistanceEnabled
    maxWeight
    maxWeightEnabled
    equipmentTypes
    equipmentTypesEnabled
    pickupFilter
    pickupFilterMaxDistance
    pickupFilterEnabled
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffFilterEnabled
    pickupStartDate
    pickupStartDateEnabled
    pickupEndDate
    pickupEndDateEnabled
    showBookableOnly
    createdAt
    updatedAt
  }
}
    `;
export const UpdatePreferences = gql`
    mutation UpdatePreferences($preferences: PreferencesInput!) {
  updatePreferences(preferences: $preferences) {
    id
    ownerId
    cities
    minDistance
    minDistanceEnabled
    maxDistance
    maxDistanceEnabled
    maxWeight
    maxWeightEnabled
    equipmentTypes
    equipmentTypesEnabled
    pickupFilter
    pickupFilterMaxDistance
    pickupFilterEnabled
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffFilterEnabled
    pickupStartDate
    pickupStartDateEnabled
    pickupEndDate
    pickupEndDateEnabled
    showBookableOnly
    createdAt
    updatedAt
  }
}
    `;
export const GetPushRegistration = gql`
    query GetPushRegistration($id: String!, $env: String) {
  getPushRegistration(id: $id, env: $env) {
    id
    token
    ownerId
    createdAt
    updatedAt
  }
}
    `;
export const CreatePushRegistration = gql`
    mutation CreatePushRegistration($pushRegistration: PushRegistrationInput!, $env: String) {
  createPushRegistration(pushRegistration: $pushRegistration, env: $env) {
    id
    token
    ownerId
    createdAt
    updatedAt
  }
}
    `;
export const UpdatePushRegistration = gql`
    mutation UpdatePushRegistration($pushRegistration: PushRegistrationInput!) {
  updatePushRegistration(pushRegistration: $pushRegistration) {
    id
    token
    ownerId
    createdAt
    updatedAt
  }
}
    `;
export const ListAllSavedSearch = gql`
    query ListAllSavedSearch($where: Any, $page: Int = 0, $offset: Int = null, $limit: Int = 20) {
  listSavedSearch(
    where: $where
    page: $page
    offset: $offset
    limit: $limit
    published: false
  ) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;
export const GetSavedSearch = gql`
    query GetSavedSearch($id: String!, $env: String) {
  getSavedSearch(id: $id, env: $env) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;
export const CreateSavedSearch = gql`
    mutation CreateSavedSearch($savedSearch: SavedSearchInput!, $env: String) {
  createSavedSearch(savedSearch: $savedSearch, published: false, env: $env) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffFilter
    dropoffFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;
export const UpdateSavedSearch = gql`
    mutation UpdateSavedSearch($savedSearch: SavedSearchInput!) {
  updateSavedSearch(savedSearch: $savedSearch) {
    id
    ownerId
    minDistance
    maxDistance
    maxWeight
    equipmentTypes
    pickupFilter
    pickupFilterMaxDistance
    dropoffStartDate
    pickupStartDate
    dropoffEndDate
    pickupEndDate
    maxStops
    showBookableOnly
    loadId
    title
    createdAt
    updatedAt
  }
}
    `;
export const DeleteSavedSearch = gql`
    mutation DeleteSavedSearch($id: String!) {
  deleteSavedSearch(id: $id)
}
    `;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  'possibleTypes': {}
};
      export default result;
    