import { BookedLoad } from '../../generated/graphql';
import { TruckloadShipmentStatus } from '../p44-models/truckloadShipmentStatus';
import { TruckloadShipmentStopStatus } from '../p44-models/truckloadShipmentStopStatus';
import { InjectLogger } from '../decorators';
import { Logger } from '../logger';
import { TruckloadShipmentStatusUpdate } from '../p44-models/truckloadShipmentStatusUpdate';
import { TruckloadShipmentStatusReason } from '../p44-models/truckloadShipmentStatusReason';
import ArrivalCodeEnum = TruckloadShipmentStopStatus.ArrivalCodeEnum;
import CodeEnum = TruckloadShipmentStatusReason.CodeEnum;
import StatusCodeEnum = TruckloadShipmentStopStatus.StatusCodeEnum;

export class StatusArrivalEstimate {
  stopNumber: number;
  estimate: string;
  statusOnTime: string;
  statusOnTimeColorClass: string;
}

export class TrackingStatusService {
  @InjectLogger() static logger: Logger;

  static getUpdatedTrackingStatus(
    bookedLoad: BookedLoad,
    currentTrackingStatus: string = null
  ): {
    statusOnTime: string;
    statusOnTimeColorClass: string;
    statusArrivalEstimate: string;
    trackingStatusColorClass: string;
    trackingStatus: string;
  } {
    const truckloadTrackingStatus = bookedLoad.trackingStatus as TruckloadShipmentStatus;

    let statusOnTime = null;
    let statusOnTimeColorClass = 'text-gray-500';
    let statusArrivalEstimate = null;
    let trackingStatusColorClass = 'text-gray';
    let trackingStatus = null;

    /*
        "DISPATCHED" "IN_TRANSIT" "AT_STOP" "COMPLETED" "TRACKING_FAILED" "INFO" "DELETED"
    */
    if (
      !!truckloadTrackingStatus &&
      !!truckloadTrackingStatus.latestStatusUpdate &&
      !!truckloadTrackingStatus.latestStatusUpdate.statusCode
    ) {
      // if we have a booked status, check the tracking info
      const haveTrackingStatusReason =
        !!truckloadTrackingStatus.latestStatusUpdate.statusReason &&
        !!truckloadTrackingStatus.latestStatusUpdate.statusReason.code;

      const statusCode = haveTrackingStatusReason ? truckloadTrackingStatus.latestStatusUpdate.statusReason.code : null;
      /*
          "PENDING_TRACKING_METHOD" "SCHEDULED" "PENDING_APPROVAL" "ACQUIRING_LOCATION" "PENDING_CARRIER" "IN_MOTION"
          "IDLE" "APPROVAL_DENIED" "TIMED_OUT" "CANCELED" "DEPARTED_FINAL_STOP" "ARRIVED_FINAL_STOP" "FAILED_TO_ACQUIRE_LOCATION" "INFO"
          The reason code for the status of the status update.
          A status of 'DISPATCHED' will have one of the following reason codes:
            'PENDING_TRACKING_METHOD', 'SCHEDULED', 'PENDING_APPROVAL', 'PENDING_CARRIER' or 'ACQUIRING_LOCATION'.
          A status of 'IN_TRANSIT' will have one of the following reason codes:
            'IN_MOTION' or 'IDLE'.
          A status of 'AT_STOP' will not have a reason.
          A status of 'COMPLETED' will have one of the following reason codes:
            'APPROVAL_DENIED', 'TIMED_OUT', 'CANCELED', or 'DEPARTED_FINAL_STOP'.
       */

      const latestStopNumber = truckloadTrackingStatus.latestStatusUpdate.stopNumber;
      // get the progress towards this stop
      const latestStopStatus = truckloadTrackingStatus.latestStopStatuses.find(s => s.stopNumber === latestStopNumber);
      const firstStopStatus = truckloadTrackingStatus.latestStopStatuses.find(s => s.stopNumber === 1);
      // FIXME - MM
      // const lastStopStatus = truckloadTrackingStatus.latestStopStatuses.find(s => s.stopNumber === bookedLoad.load.stops.length);
      const lastStopStatus = null;
      this.logger.debug('getUpdatedTrackingStatus(): got first stop status', firstStopStatus);
      this.logger.debug('getUpdatedTrackingStatus(): got latest stop status', latestStopStatus);
      this.logger.debug('getUpdatedTrackingStatus(): got last stop status', lastStopStatus);

      const stopStatus = !!latestStopStatus ? latestStopStatus : !!firstStopStatus ? firstStopStatus : lastStopStatus;
      let unknownStatus = false;
      if (!!stopStatus) {
        switch (stopStatus.statusCode) {
          case TruckloadShipmentStopStatus.StatusCodeEnum.Arrived:
            statusOnTime = 'Arrived';
            break;
          case TruckloadShipmentStopStatus.StatusCodeEnum.Departed:
            statusOnTime = 'Departed';
            break;
          case TruckloadShipmentStopStatus.StatusCodeEnum.EnRoute:
            statusOnTime = 'Enroute';
            break;
          case TruckloadShipmentStopStatus.StatusCodeEnum.Unknown:
            statusOnTime = 'Unknown';
            unknownStatus = true;
            break;
        }

        switch (stopStatus.arrivalCode) {
          case ArrivalCodeEnum.Early:
            if (unknownStatus) {
              statusOnTime = 'Early';
            } else {
              statusOnTime += ' - Early';
            }
            statusOnTimeColorClass = 'text-green-500';
            break;
          case ArrivalCodeEnum.OnTime:
            if (unknownStatus) {
              statusOnTime = 'On Time';
            } else {
              statusOnTime += ' - On Time';
            }
            statusOnTimeColorClass = 'text-green-500';
            break;
          case ArrivalCodeEnum.Late:
            if (unknownStatus) {
              statusOnTime = 'Late';
            } else {
              statusOnTime += ' - Late';
            }
            statusOnTimeColorClass = 'text-red-500';
            break;
          case ArrivalCodeEnum.Unknown:
            // FIXME - not sure it's useful to display this
            // statusOnTime = 'Unknown';
            // statusOnTimeColorClass = 'text-gray-500';
            break;
        }

        // FIXME - ETA to be added
        // if (!!stopStatus.arrivalEstimate && stopStatus.arrivalEstimate.estimatedArrivalWindow) {
        //   // const stopForEta = bookedLoad.load.stops[stopStatus.stopNumber - 1];
        //   statusArrivalEstimate.stopNumber = stopStatus.stopNumber;
        //   statusArrivalEstimate.estimate =
        //     moment(stopStatus.arrivalEstimate.estimatedArrivalWindow.startDateTime).format('M/d h:mma') + ' - ' +
        //     moment(stopStatus.arrivalEstimate.estimatedArrivalWindow.endDateTime).format('M/d h:mma') + ')'
        // }
      }

      switch (truckloadTrackingStatus.latestStatusUpdate.statusCode) {
        case TruckloadShipmentStatusUpdate.StatusCodeEnum.Dispatched:
          trackingStatus = 'Dispatched - ';
          trackingStatusColorClass = 'text-teal-500';
          switch (statusCode) {
            case CodeEnum.PendingTrackingMethod:
              trackingStatus += 'Pending Tracking Method';
              break;
            case CodeEnum.Scheduled:
              trackingStatus += 'Scheduled';
              break;
            case CodeEnum.PendingApproval:
              trackingStatus += 'Pending Approval';
              break;
            case CodeEnum.PendingCarrier:
              trackingStatus += 'Pending Carrier';
              break;
            case CodeEnum.AcquiringLocation:
              trackingStatus += 'Acquiring Location';
              break;
          }
          break;
        case TruckloadShipmentStatusUpdate.StatusCodeEnum.InTransit:
          trackingStatus = 'In Transit - ';
          switch (statusCode) {
            case CodeEnum.InMotion:
              trackingStatusColorClass = 'text-loadsmith-orange-500';
              trackingStatus += 'In Motion';
              break;
            case CodeEnum.Idle:
              trackingStatus += 'Idle';
              break;
          }
          break;
        case TruckloadShipmentStatusUpdate.StatusCodeEnum.AtStop:
          // only use this status if a better one hasn't arrived yet
          if (currentTrackingStatus !== 'Arrived Final Stop') {
            trackingStatus = 'Arrived at Stop';
            trackingStatusColorClass = 'text-loadsmith-orange-500';
          }
          break;
        case TruckloadShipmentStatusUpdate.StatusCodeEnum.Completed:
          trackingStatus = 'Completed - ';
          switch (statusCode) {
            case CodeEnum.ApprovalDenied:
              trackingStatus = 'Approval Denied';
              trackingStatusColorClass = 'text-red-500';
              break;
            case CodeEnum.TimedOut:
              trackingStatus = 'Timed Out';
              trackingStatusColorClass = 'text-red-500';
              break;
            case CodeEnum.Canceled:
              trackingStatus = 'Canceled';
              trackingStatusColorClass = 'text-red-500';
              break;
            case CodeEnum.DepartedFinalStop:
              trackingStatus = 'Departed Final Stop';
              trackingStatusColorClass = 'text-loadsmith-orange-500';
              break;
            case CodeEnum.ArrivedFinalStop:
              trackingStatus = 'Arrived Final Stop';
              trackingStatusColorClass = 'text-loadsmith-orange-500';
              break;
          }
          break;
        case TruckloadShipmentStatusUpdate.StatusCodeEnum.TrackingFailed:
          trackingStatus = 'Tracking Failed';
          trackingStatusColorClass = 'text-red-500';
          break;
        case TruckloadShipmentStatusUpdate.StatusCodeEnum.Info:
          trackingStatus = 'Error';
          trackingStatusColorClass = 'text-red-500';
          break;
        case TruckloadShipmentStatusUpdate.StatusCodeEnum.Deleted:
          trackingStatus = 'Deleted';
          trackingStatusColorClass = 'text-red-500';
          break;
      }
    } else {
      trackingStatus = null;
    }

    return {
      statusOnTime,
      statusOnTimeColorClass,
      statusArrivalEstimate,
      trackingStatusColorClass,
      trackingStatus
    };

    // status updates array in 'statusUpdates'
    // {
    //   "timestamp": "string",
    //   "statusCode": "DISPATCHED",
    //   "statusReason":
    //     {
    //       "code": "PENDING_TRACKING_METHOD",
    //       "description": "string"
    //     },
    //   "geoCoordinates":
    //     {
    //       "latitude": 0,
    //       "longitude": 0
    //     },
    //   "address":
    //     {
    //       "postalCode": "string",
    //       "addressLines":
    //
    //         [
    //           "string"
    //         ],
    //       "city": "string",
    //       "state": "string",
    //       "country": "US"
    //     },
    //   "stopNumber": 0
    // }
  }
}
