import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { take } from 'rxjs/operators';
import { AppSandbox } from './app.sandbox';
import { Config } from './config';

export function createApollo(httpLink: HttpLink, sandbox: AppSandbox): ApolloClientOptions<any> {
  const auth = setContext((operation, context) => {
    const token = sandbox.authToken$.pipe(take(1)).toPromise();
    this.logger.debug('apollo using token', !!token);

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: token
        }
      };
    }
  });

  const cache = new InMemoryCache();

  return {
    link: auth.concat(httpLink.create({ uri: Config.contentApiBaseGraphqlUrl })),
    cache
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AppSandbox]
    }
  ]
})
export class GraphQLModule {}
