import { Logger } from './logger';

/**
 * Injects a Log object
 */
export function InjectLogger(name: string = 'default'): Function {
  return function (target: Function, key: string, index: number) {
    // TODO - support multiple loggers by name param above
    // this.logger.debug('inject logger', target.constructor.name);
    target[key] = new Logger(target);
  };
}
