import { Component, HostListener, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { AlertController, LoadingController, MenuController, Platform } from '@ionic/angular';
import { filter, mergeMap, mergeMapTo, take, tap } from 'rxjs/operators';
import { InjectLogger } from './decorators';
import { Logger } from './logger';
import { AuthUtilService } from './services/authUtil.service';
import { Global } from './services/global.service';
import { AppSandbox } from './app.sandbox';
import { PreferencesService } from './services/preferences.service';
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import to from 'await-to-js';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AuthenticationService } from './services/authentication.service';
import { getMessaging, onMessage } from 'firebase/messaging';
import { PushMessagingService } from './services/pushMessaging.service';
import { LocalNotificationDescriptor, LocalNotifications, ScheduleResult } from '@capacitor/local-notifications';
import { FCM } from '@capacitor-community/fcm';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';

import { cloneDeep } from '@apollo/client/utilities';
import { BookedLoad } from '../generated/graphql';
import { TrackingStatusService } from './services/trackingStatus.service';
import { ThemeService } from './services/ThemeService';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @InjectLogger() logger: Logger;
  admin = false;
  lastRoute: string = null;
  thinking = true;
  showRequestPushNotifications = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // Global = Global;
  faTruckMoving = faTruckMoving;
  onLogin = true;
  onBoarding = true;
  notificationDescriptors: LocalNotificationDescriptor[] = [];
  authenticated = false;
  isWeb = null;
  profile;
  isDarkMode: boolean;
  isDropdownOpen = false;

  constructor(
    private menu: MenuController,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
    private platform: Platform,
    private loadingController: LoadingController,
    private authService: AuthenticationService,
    private authUtilService: AuthUtilService,
    private appSandbox: AppSandbox,
    private preferencesService: PreferencesService,
    private pushMessagingService: PushMessagingService,
    private alertController: AlertController,
    private themeService: ThemeService,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {
    this.checkForWeb(window.screen.width);
    // Subscribe to router events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isDropdownOpen = false;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkForWeb(event.target.innerWidth);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    // Check if the click is outside the component
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }

  checkForWeb(screenWidth: number) {
    const isWeb = this.platform.is('capacitor') === false && screenWidth >= 768;
    this.logger.debug('checkForWeb()', screenWidth, isWeb);

    let redirectToMobile = false,
      redirectToWeb = false;
    if (this.isWeb !== false && isWeb === false) {
      redirectToMobile = true;
    } else if (this.isWeb === false && isWeb === true) {
      redirectToWeb = true;
    }

    this.isWeb = isWeb;
    Global.isWeb.next(this.isWeb);
    // this.logger.debug('checkForWeb(): sent isWeb', isWeb);

    if (redirectToMobile) {
      this.logger.debug('checkForWeb(): redirecting to mobile');
      this.router.navigateByUrl('/mobile');
    }
    if (redirectToWeb) {
      this.logger.debug('checkForWeb(): redirecting to desktop');
      this.router.navigateByUrl('/');
    }
  }

  async ngOnInit() {
    this.logger.debug('ngOnInit()');
    this.appSandbox.profile$.subscribe(profile => (this.profile = profile));

    this.logger.debug('ngOnInit(): profile', this.profile);

    this.themeService.isDarkMode.subscribe((isDarkMode: boolean) => {
      this.isDarkMode = isDarkMode;
      if (isDarkMode) {
        document.body.classList.add('dark-theme');
        document.body.classList.remove('light-theme');
      } else {
        document.body.classList.add('light-theme');
        document.body.classList.remove('dark-theme');
      }
    });

    this.checkForWeb(window.innerWidth);

    // check for login page
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!!event.url && event.url.match(/\/login/)) {
        this.logger.debug('ngOnInit(): login page');
        this.onLogin = true;
      } else if (!!event.url && event.url.match(/\/profile/)) {
        this.logger.debug('ngOnInit(): profile page');
        this.onBoarding = true;
      } else {
        this.logger.debug('ngOnInit(): NOT login page');
        this.onLogin = false;
        this.onBoarding = false;
      }
    });

    this.platform.ready().then(async () => {
      // initialize
      this.initializeApp();

      await this.presentLoading();

      // validate our current token
      this.logger.debug('ngOnInit(): validating token');
      const [err, res] = await to(this.authService.validateToken());
      this.logger.debug('ngOnInit(): done validating token', err, res);

      this.authUtilService.isAuthenticated$.subscribe(async loggedIn => {
        this.logger.debug('ngOnInit(): logged in', loggedIn);

        if (loggedIn) {
          this.authenticated = true;

          // load preferences on app start
          let [err, preferences] = await to(this.preferencesService.getPreferences());

          if (!!err) {
            this.logger.error('ngOnInit(): failed to get preferences', err);
            await this.showError(
              `There was an error loading your preferences. Restart your app or contact support@loadsmith.com`
            );
          } else {
            this.logger.debug('ngOnInit(): got preferences', preferences);
            // update the local preferences, even if null, but only if the prefs look like they are from backend
            const currentPrefs = this.appSandbox.getCurrentPreferences();
            if (!!currentPrefs && !!currentPrefs.id) {
              await this.appSandbox.updatePreferences(preferences);
            }
          }
        }
      });

      Global.activity.subscribe(isActive => {
        this.logger.debug('ngOnInit(): isActive', isActive);

        this.thinking = isActive;
      });

      await this.initializeFirebase();

      window.dispatchEvent(new Event('resize'));
      await this.dismissLoading();
    });
  } // end ngOnInit()

  async initializeFirebase() {
    this.logger.debug('initializeFirebase(): have platforms', this.platform.platforms());

    if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
      // FIREBASE

      const [err, perms] = await to(PushNotifications.checkPermissions());
      if (!!err) {
        this.logger.warn('ngOnInit(): unable to configure push notifications', err);
        return;
      }

      this.logger.debug('initializeFirebase(): got push notification permissions', perms);

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
          this.logger.error('AppComponent(): ngOnInit(): failed to register for push notifications');
          this.showError(
            `Error registering for push notifications. Please restart the application or contact support if this issue persists`
          );
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        this.logger.debug('ngOnInit(): registration token', token);

        if (this.platform.is('ios')) {
          this.logger.debug('ngOnInit(): using FCM to get real registration token for iOS');

          // in this way, the token return doesn't work for Android!!!
          FCM.getToken()
            .then(r => {
              this.logger.debug('ngOnInit(): got real registration token for iOS', r.token);
              // now register this token with the customer on the server
              this.pushMessagingService.queuePushRegistration(r.token);
            })
            .catch(err => {
              this.logger.error('ngOnInit(): error getting token with FCM', err);
            });
        } else {
          // now register this token with the customer on the server
          this.logger.debug('ngOnInit(): using registration token');
          this.pushMessagingService.queuePushRegistration(token.value);
        }
      });

      // Some issue with our setup   and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        this.logger.debug('ngOnInit(): registration error', error);
        // alert('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        this.logger.debug('ngOnInit(): pushNotificationReceived', notification);

        await this.handlePushNotification(notification.data);
      });

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        this.logger.debug('ngOnInit(): pushNotificationActionPerformed', notification);
      });

      PushNotifications.register();

      LocalNotifications.addListener('localNotificationActionPerformed', notificationAction => {
        this.logger.debug('ngOnInit(): localNotificationActionPerformed', notificationAction);

        if (notificationAction.actionId === 'tap') {
          // TODO - do something on click based on title - notificationAction.notification.title
        }
      });

      await FirebaseMessaging.requestPermissions();

      // dismiss all notifications when app is resumed
      this.platform.resume.subscribe(async () => {
        this.logger.debug('ngOnInit(): app resumed');
        if (!!this.notificationDescriptors && this.notificationDescriptors.length > 0) {
          await LocalNotifications.cancel({
            notifications: this.notificationDescriptors
          });
        }
        this.notificationDescriptors = [];
      });

      FirebaseMessaging.removeAllListeners().then(() => {
        FirebaseMessaging.addListener('tokenReceived', event => {
          this.logger.debug('AppComponent(): ngOnInit(): tokenReceived', event);
          this.pushMessagingService.queuePushRegistration(event.token);
        });

        FirebaseMessaging.addListener('notificationReceived', event => {
          this.logger.debug('AppComponent(): ngOnInit(): notificationReceived', event.notification);
          this.handlePushNotification(event.notification.data);
        });

        FirebaseMessaging.addListener('notificationActionPerformed', event => {
          this.logger.debug(`AppComponent(): ngOnInit(): notificationActionPerformed`, { event });
        });
      });

      if (!Capacitor.isNativePlatform()) {
        navigator.serviceWorker.addEventListener('message', (event: any) => {
          this.logger.debug(`AppComponent(): ngOnInit(): serviceWorker message`, { event });
          this.handlePushNotification(event.notification.data);
        });
      }
    } else {
      // web initialization of push messaging
      this.logger.debug('ngOnInit(): registering web push messaging');
      this.registerPushMessaging();
    }
  }

  async handlePushNotification(data) {
    this.logger.debug('handlePushNotification(): data', data);

    let message: any = data.content;
    if (typeof data.content === 'string') {
      message = JSON.parse(data.content);
    }

    this.logger.debug('handlePushNotification(): got message', data.operation, message);

    Global.pushNotificationRecieved.next({
      operation: data.operation,
      content: message
    });

    let notif: ScheduleResult;

    if (data.operation === 'loadUpdate' || data.operation === 'trackingUpdate') {
      // adjust the local booked load entry
      const origBookedLoads = this.appSandbox.getBookedLoads();
      const bookedLoads = origBookedLoads.map(l => cloneDeep(l));
      this.logger.debug('handlePushNotification(): current booked loads', bookedLoads);

      const updatedBookedLoad = message as BookedLoad;

      // replace the old booked load entry with this updated one
      const newBookedLoad = bookedLoads.find(l => l.id === updatedBookedLoad.id);

      // update the booked load
      newBookedLoad.inactive = updatedBookedLoad.inactive;
      newBookedLoad.archived = updatedBookedLoad.archived;
      newBookedLoad.accepted = updatedBookedLoad.accepted;
      newBookedLoad.rate = updatedBookedLoad.rate;
      newBookedLoad.quoteAmount = updatedBookedLoad.quoteAmount;

      // replace the old load with the updated version
      const idx = bookedLoads.findIndex(l => l.id === updatedBookedLoad.id);
      bookedLoads.splice(idx, 1, newBookedLoad);

      this.logger.debug('handlePushNotification(): updated booked loads', bookedLoads);

      if (data.operation === 'loadUpdate') {
        this.logger.debug('handlePushNotification(): got a load update', message);

        // update booked loads
        this.appSandbox.updateBookedLoads(bookedLoads);

        // FIXME - MM
        // // get some of the vars for notification
        // const referenceId = newBookedLoad.load.referenceId;
        // const pickupCity = newBookedLoad.load.pickupCity;
        // const pickupState = newBookedLoad.load.pickupState;
        // const dropoffCity = newBookedLoad.load.dropoffCity;
        // const dropoffState = newBookedLoad.load.dropoffState;
        //
        // // get updated vars
        // const rate = updatedBookedLoad.rate;
        // const quoteAmount = updatedBookedLoad.quoteAmount;
        //
        // // see what type of notification we should send
        // notif = await LocalNotifications.schedule({
        //   notifications: [
        //     {
        //       title: updatedBookedLoad.accepted === true ?
        //         `Booked load confirmed (${referenceId})` :
        //         `Booked load denied (${referenceId})`,
        //       body: updatedBookedLoad.accepted === true ?
        //         `Route: ${pickupCity}, ${pickupState} to ${dropoffCity}, ${dropoffState}. ` + rate != null ? `Rate: $${rate}` : '' :
        //         `Route: ${pickupCity}, ${pickupState} to ${dropoffCity}, ${dropoffState}. ` + (!!quoteAmount || !!rate) ? `Rate: $${!!quoteAmount ? quoteAmount : rate}` : '',
        //       id: new Date().getTime(),
        //       schedule: {at: new Date(Date.now() + 1000 * 5)}
        //     }
        //   ]
        // });
      } else if (data.operation === 'trackingUpdate') {
        this.logger.debug('handlePushNotification(): got a tracking update', newBookedLoad);

        const haveChanges = JSON.stringify(newBookedLoad) === JSON.stringify(updatedBookedLoad);
        this.logger.debug('handlePushNotification(): have changes?', haveChanges);

        // update the tracking status as well
        newBookedLoad.trackingStatus = updatedBookedLoad.trackingStatus;

        // update booked loads
        this.appSandbox.updateBookedLoads(bookedLoads.concat([]));

        const status = TrackingStatusService.getUpdatedTrackingStatus(updatedBookedLoad);

        // only push a web notification when the status is a major status update (arrival, departure)
        if (!!updatedBookedLoad.trackingStatus) {
          switch (updatedBookedLoad.trackingStatus.statusCode) {
            case 'SCHEDULED':
            case 'DISPATCHED':
            case 'COMPLETED':
              // tracking update
              this.logger.debug(
                'handlePushNotification(): got status code that should show a notification',
                updatedBookedLoad.trackingStatus.statusCode
              );
              // FIXME - MM
              // notif = await LocalNotifications.schedule({
              //   notifications: [
              //     {
              //       title: `Tracking Update (${updatedBookedLoad.load.referenceId})`,
              //       body: `Status: ${status.statusOnTime}, Tracking: ${status.trackingStatus}`,
              //       // , ETA: ${status.statusArrivalEstimate} - ETA here
              //       id: new Date().getTime(),
              //       schedule: {at: new Date(Date.now())}
              //     }
              //   ]
              // });

              break;
            default:
              this.logger.debug(
                'handlePushNotification(): got status code that should NOT show a notification',
                updatedBookedLoad.trackingStatus.statusCode
              );
              break;
          }
        }
      }
    } else {
      this.logger.debug('handlePushNotification(): unknown type');
      if (!!message.title && !!message.content) {
        // just notify if we have a title and content
        notif = await LocalNotifications.schedule({
          notifications: [
            {
              title: message.title,
              body: message.content,
              id: new Date().getTime(),
              schedule: { at: new Date(Date.now() + 1000 * 5) }
            }
          ]
        });
      }
    }

    if (!!notif) {
      this.notificationDescriptors.push(...notif.notifications);
    }
  }

  registerPushMessaging() {
    this.requestPermission();
    this.listen();
  }

  requestPermission() {
    FirebaseMessaging.requestPermissions();

    // const messaging = getMessaging();
    // getToken(messaging,
    //   {
    //     vapidKey: Config.cloudMessagingKey
    //   }).then((currentToken) => {
    //   if (currentToken) {
    //     this.logger.debug('requestPermission(): we got the token', currentToken);
    //     this.pushMessagingService.queuePushRegistration(currentToken);
    //   } else {
    //     this.logger.warn('requestPermission(): no registration token available. request permission to generate one.');
    //   }
    // }).catch((err) => {
    //   this.logger.error('requestPermission(): error occurred while retrieving token. ', err);
    // });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, async payload => {
      this.logger.debug('listen(): message notification received', payload);
      // alert(`message received: ${JSON.stringify(payload)}`)
      await this.handlePushNotification(payload.data);
    });
  }

  enableNotifications() {
    Notification.requestPermission().then(status => {
      this.logger.debug('enableNotifications(): local notifications', status);

      if (status === 'denied') {
        // do something
        this.logger.debug('enableNotifications(): local notifications denied');
      } else if (status === 'granted') {
        // do something
        this.logger.debug('enableNotifications(): local notifications granted');
      }
    });
  }

  // requestNotificationPermission() {
  //   this.afMessaging.requestPermission.pipe(mergeMapTo(this.afMessaging.tokenChanges)).subscribe(
  //     token => {
  //       this.logger.debug('requestNotificationPermission(): Permission granted! Save to the server!', token);
  //       this.registerPushMessaging();
  //     },
  //     error => {
  //       this.logger.error('requestNotificationPermission()', error);
  //     }
  //   );
  //
  //   // Notification.requestPermission().then((status) => {
  //   //   if (status === 'denied') {
  //   //     //do something
  //   //   } else if (status === 'granted') {
  //   //     //do something
  //   //     this.registerPushMessaging();
  //   //   }
  //   // });
  // }
  //
  // deleteToken() {
  //   this.afMessaging.getToken.pipe(mergeMap(token => this.afMessaging.deleteToken(token))).subscribe(token => {
  //     this.logger.debug('deleteToken(): Token deleted!');
  //   });
  // }

  openMenu() {
    this.logger.debug('openMenu()');
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('App opened with URL:', event);

      this.zone.run(() => {
        this.logger.debug(
          'initializeApp(): opened app by link',
          event.url,
          event.iosSourceApplication,
          event.iosOpenInPlace
        );
        let parts = event.url.split('://loadsmith-links.appbloks.site');
        this.logger.debug('initializeApp(): got parts', parts);

        let slug;
        if (!!parts && parts.length > 0) {
          slug = parts.pop();
        } else {
          parts = event.url.split('://loadsmith');
          this.logger.debug('initializeApp(): got parts (alt parse)', parts);
          if (!!parts && parts.length > 0) {
            slug = parts.pop();
          }
        }

        if (!!slug) {
          this.logger.debug('initializeApp(): slug', slug);
          this.router.navigateByUrl(slug);
        }

        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    App.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed. Is active?', isActive);
    });

    App.addListener('appRestoredResult', data => {
      console.log('Restored state:', data);
    });

    const checkAppLaunchUrl = async () => {
      const { url } = await App.getLaunchUrl();

      alert('App opened with URL: ' + url);
    };
  }

  logout(): void {
    this.authUtilService.logout(false);
    this.isDropdownOpen = false; // Close dropdown menu
    this.closeMenu(); // Close side menu
  }

  closeMenu() {
    this.logger.debug('closeMenu()');
    const self = this;
    setTimeout(() => {
      self.menu.close('custom').then();
    }, 100);
  }

  changeTheme() {
    this.themeService.toggleTheme();
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  async showError(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      // subHeader: 'Subtitle',
      message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentLoading(label = 'Loading...') {
    // this.logger.debug('presentLoading()');
    // const loader = await this.loadingController.create({
    //   cssClass: 'my-custom-class',
    //   message: label,
    // });
    // this.logger.debug('presentLoading(): present');
    // await loader.present();
    // this.logger.debug('presentLoading(): present complete');
  }

  dismissLoading() {
    // this.logger.debug('dismissLoading()');
    // setTimeout(async () => {
    //   await this.loadingController.dismiss();
    //   this.logger.debug('dismissLoading(): dismissing loading');
    // }, 0);
  }
}
