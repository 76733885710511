import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  // desktop
  {
    path: '',
    loadChildren: () =>
      import('./pages/desktop/home-page-desktop/home-page-desktop.module').then(m => m.HomePageDesktopModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'portal',
    loadChildren: () => import('./pages/desktop/app-redirect/app-redirect.module').then(m => m.AppRedirectModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/desktop/login-desktop/login-desktop.module').then(m => m.LoginDesktopModule)
  },
  {
    path: 'callback',
    loadChildren: () => import('./pages/desktop/callback/callback.module').then(m => m.CallbackModule)
  },
  {
    path: 'my-loads',
    loadChildren: () => import('./pages/desktop/my-loads/my-loads.module').then(m => m.MyLoadsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/desktop/profile-desktop/profile-page-desktop.module').then(m => m.ProfilePageDesktopModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'active-load-desktop',
    loadChildren: () => import('./pages/mobile/active-load/active-load.module').then(m => m.ActiveLoadModule),
    canActivate: [AuthGuard]
  },
  // mobile
  {
    path: 'mobile',
    loadChildren: () => import('./pages/mobile/home-page/home-page.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile/login',
    loadChildren: () => import('./pages/mobile/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'mobile/profile',
    loadChildren: () => import('./pages/mobile/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile/onboarding',
    loadChildren: () => import('./pages/mobile/onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile/preferences',
    loadChildren: () => import('./pages/mobile/preferences/preferences.module').then(m => m.PreferencesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile/filters',
    loadChildren: () => import('./pages/mobile/filters/filters.module').then(m => m.FiltersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile/available-loads',
    loadChildren: () =>
      import('./pages/mobile/available-loads/available-loads.module').then(m => m.AvailableLoadsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile/load-history',
    loadChildren: () => import('./pages/mobile/load-history/load-history.module').then(m => m.LoadHistoryModule),
    canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
