import { take } from 'rxjs/operators';
import to from 'await-to-js';
import {
  AuthenticateDocument,
  AuthenticationResult,
  JwtDecoded,
  PasswordlessAuthenticateDocument,
  PasswordlessOtpDocument,
  ValidateTokenDocument
} from '../../generated/graphql';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { InjectLogger } from '../decorators';
import { Logger } from '../logger';
import { AppSandbox } from '../app.sandbox';
import { AuthUtilService } from './authUtil.service';
import { Router } from '@angular/router';
import { AuthenticationTokensAndProfile, AuthService } from '../../generated-client';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  @InjectLogger() logger: Logger;

  constructor(
    private router: Router,
    private apollo: Apollo,
    private appSandbox: AppSandbox,
    private authService: AuthService,
    private authUtilService: AuthUtilService
  ) {
    this.authService.configuration.basePath = Config.integrationApiBaseRestUrl;
  }

  async validateToken() {
    this.logger.debug('validateToken()');

    const token = await this.appSandbox.authToken$.pipe(take(1)).toPromise();

    if (!token) {
      // no token, logout
      this.appSandbox.logout();
      return;
    }

    const [error, res] = await to(
      this.apollo
        .query<any>({
          query: ValidateTokenDocument,
          variables: {
            token
          },
          fetchPolicy: 'no-cache'
        })
        .toPromise()
    );

    this.logger.debug('validateToken(): result', !!res ? res.data : 0, error);
    if (error) {
      this.logger.debug('validateToken(): error validating token', error);
      await this.appSandbox.logout();
      throw error;
    }

    const result: JwtDecoded = res.data.validateToken;
    this.logger.debug('validateToken(): validated token, got decoded', result);
    await this.authUtilService.checkTokens();
    return result;
  }

  async passwordlessOTP(email: string, phone: string, name: string, poolName = 'loadsmith', callbackUrl = null) {
    this.logger.debug('passwordlessOTP()', email, phone, poolName);

    this.authService.configuration.basePath = Config.integrationApiBaseRestUrl;

    const [err, res] = await to(
      this.authService
        .postAuthPasswordlessOtp(
          null,
          {
            email,
            phone,
            name,
            pool: poolName
          },
          'body',
          false,
          {
            httpHeaderAccept: 'application/json' as any
          }
        )
        .toPromise()
    );

    this.logger.debug('passwordlessOTP(): got response', res);

    if (err) {
      this.logger.debug('passwordlessOTP(): got error', err.message);
      throw err;
    }
    return res;
  }

  async passwordlessAuthenticate(
    email: string,
    phone: string,
    dotNumber: string,
    code: string,
    name: string
  ): Promise<AuthenticationTokensAndProfile> {
    this.logger.debug('passwordlessAuthenticate()', email, phone, code);

    this.authService.configuration.basePath = Config.integrationApiBaseRestUrl;

    const [err, res] = await to(
      this.authService
        .postAuthPasswordlessAuthenticate(
          null,
          {
            email,
            phone,
            dotNumber,
            code
          },
          'body',
          false,
          {
            httpHeaderAccept: 'application/json' as any
          }
        )
        .toPromise()
    );

    this.logger.debug('passwordlessAuthenticate(): got response', res);

    if (err) {
      this.logger.debug('passwordlessAuthenticate(): got error', err.message);
      throw err;
    }

    return res;
  }

  async authenticate(email: string, phone: string, password: string): Promise<AuthenticationResult> {
    this.logger.debug('authenticate()', email, phone);

    const [error, res] = await to(
      this.apollo
        .query<any>({
          query: AuthenticateDocument,
          variables: {
            email,
            phone,
            password
          },
          fetchPolicy: 'no-cache'
        })
        .toPromise()
    );

    this.logger.debug('authenticate(): result', !!res ? res.data.authenticate : 0, error);
    if (error) {
      throw error;
    }

    return res.data.authenticate;
  }
}
